import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import OutletListContainer from "./outletsListContainer";
import Header from "../../components/header";
import {
  fetchOrgID,
  fetchIsOverviewAccount,
} from "../../functions/organization";
import { fetchOutlets } from "../../functions/outletList";
import { fetchWattHours } from "../../functions/wattHours";
import OrganizationPicker from "../../components/orgPicker";
import { green, greyDark, greyLight, greyUltraLight } from "../../lib/colors";
import { fetchUserRole } from "../../functions/users";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function ApplianceScreen() {
  const [orgID, setOrgID] = useState(localStorage.getItem("orgID") || "");
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC"
  );
  const [rate, setRate] = useState(localStorage.getItem("rate") || "0.16");
  const [outlets, setOutlets] = useState(
    JSON.parse(localStorage.getItem("outlets"))
  );
  const [wattHours, setWattHours] = useState([]);
  const [outletsLoading, setOutletsLoading] = useState(true);
  const [wattsLoading, setWattsLoading] = useState(true);
  const [selectedOrgID, setSelectedOrgID] = useState(
    localStorage.getItem("selectedOrgID") || ""
  );
  const [prodTypes, setProdTypes] = useState([]);
  const [allProdTypes, setAllProdTypes] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isOverviewAccount, setIsOverviewAccount] = useState(localStorage.getItem("isOverviewAccount") === "true" || false);

  // This is for filtering
  const [selectedApplianceType, setSelectedApplianceType] = useState("");
  const handleApplianceTypeChange = (event) => {
    const selectedApplianceType = event.target.value;
    setSelectedApplianceType(selectedApplianceType);
    console.log("Fetching...");
    if (selectedOrgID !== "") {
      fetchWattHours(
        selectedOrgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
    } else {
      fetchWattHours(
        orgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
    }
    console.log("Fetched!!");
  };


  // gets OrgID
  useEffect(() => {
    // // Function to fetch data from Firestore
    const fetchAndSetOrgID = async () => {
      if (orgID == "") {
        const {
          orgID: fetchedOrgID,
          timezone: fetchedTimezone,
          rate: fetchedRate,
        } = await fetchOrgID();
        if (fetchedOrgID) {
          setOrgID(fetchedOrgID);
          localStorage.setItem("orgID", fetchedOrgID);
          fetchIsOverviewAccount(fetchedOrgID, setIsOverviewAccount)
        }
        if (fetchedTimezone) {
          setTimezone(fetchedTimezone);
          localStorage.setItem("timezone", fetchedTimezone);
        }
        if (fetchedRate) {
          setRate(fetchedRate);
          localStorage.setItem("rate", fetchedRate);
        }
      }
    };
    fetchAndSetOrgID();
  }, [orgID]);

  // gets information upon orgID load if not an overview account
  useEffect(() => {
    if (isOverviewAccount == false && orgID !== "" && orgID !== 'LZqI3R6MInUuwtgtROPK') {
      setWattsLoading(true);
      setOutletsLoading(true);
      const unsubscribe = fetchOutlets(orgID, timezone, setOutlets, setOutletsLoading, setProdTypes);
      fetchWattHours(orgID, setWattHours, setWattsLoading);
      setOutletsLoading(false);
      return () => unsubscribe();
    };
  }, []);

  // gets information upon orgID load if not an overview account
  useEffect(() => {
    if (selectedOrgID !== "") {
      setWattsLoading(true);
      setOutletsLoading(true);
      const unsubscribe = fetchOutlets(selectedOrgID, timezone, setOutlets, setOutletsLoading, setProdTypes);
      fetchWattHours(selectedOrgID, setWattHours, setWattsLoading);
      setOutletsLoading(false);
      return () => unsubscribe();
    };
  }, [selectedOrgID, timezone]);
  
  // gets ALL product type info
  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        // Access a Firestore document
        let querySnapshot = await getDocs(collection(db, "productTypes"));
        let productTypesSet = new Set();
        querySnapshot.forEach((doc) => {
          productTypesSet.add(doc.id);
        });
        // Convert Set back to array and add "N/A" at the beginning
        let productTypes = ["N/A", ...Array.from(productTypesSet)];
        setAllProdTypes(productTypes);
        localStorage.setItem("productTypes", JSON.stringify(productTypes));
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchProductTypes(); // Call fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // resize function useEffect
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Render based on loading state
  return (
    <div>
    {orgID !== "" &&
    <Header
      selectedOrgID={selectedOrgID}
      setSelectedOrgID={setSelectedOrgID}
      setTimezone={setTimezone}
      setRate={setRate}
      width={windowDimensions.width} 
      isOverviewAccount={isOverviewAccount}
    />
    }
    <div style={{
      position:'absolute',
      top:65,
      minWidth: '100vw',
      minHeight: '100vh',
      backgroundColor: '#F2F4F8',
      }}>
      <div 
        style={{
          width: '100vw',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
      {wattsLoading || outletsLoading ? (
        <CircularProgress />)
      :
        <div
          style={{
            width: windowDimensions.width < 1400 ? "92.5vw" : "1300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: 10,
          }}
        >
        {prodTypes && handleApplianceTypeChange && (
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems:'center',
          // width:'100%',
          //backgroundColor:'red',
        }}>
          <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems:'center',
              //backgroundColor:'red',
            }}>
            <FormHelperText style={{fontSize: windowDimensions.width < 1300 ? 12.5: 15, fontWeight:'500',}}>
              Filter data by product type:
            </FormHelperText>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <Select
                defaultValue={'All Outlets'}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native',
                }}
                onChange={handleApplianceTypeChange}
              >
                <MenuItem value="All Outlets">
                  <em>All Outlets</em>
                </MenuItem>
                {prodTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>)}
          <OutletListContainer
            outlets={
              selectedApplianceType &&
              selectedApplianceType != "" &&
              selectedApplianceType !== "All Outlets"
                ? selectedApplianceType == "N/A"
                  ? outlets.filter((outlet) => outlet.productType === "")
                  : outlets.filter(
                      (outlet) =>
                        outlet.productType === selectedApplianceType
                    )
                : outlets
            }
            prodTypes={allProdTypes}
            setOutlets={setOutlets}
            timezone={timezone}
            wattHours={wattHours}
            rate={rate}
            height={windowDimensions.height}
            width={windowDimensions.width}
          />
        </div>
      }
      </div>
    </div>
  </div>
  );
};