'use client'

import React, { useEffect, useState } from "react"
import { Box, Typography, CircularProgress, useTheme, useMediaQuery } from "@mui/material"
import { fetchGlobalSavings } from "../../functions/savings"

const formatLargeNumber = (value, unit) => {
  const units = ['', 'K', 'M', 'G', 'T']
  let unitIndex = 0
  let scaledValue = value
  let formattedUnit = unit
  if(unit!=="lbs"){
    while (scaledValue >= 1000 && unitIndex < units.length - 1) {
      unitIndex += 1
      scaledValue /= 1000
    }
  
    formattedUnit = units[unitIndex] + unit
    if (unit === 'g') {
      formattedUnit = units[unitIndex] + 'g'
    } else if (unit === 'Wh') {
      formattedUnit = units[unitIndex] + 'Wh'
    }
  }

  return {
    value: scaledValue.toFixed(1),
    unit: formattedUnit
  }
}

const SavingsCircle = ({ value, label, color, unit }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { value: displayValue, unit: displayUnit } = formatLargeNumber(value, unit)

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={isMobile ? 3 : 0}>
      <Typography variant="h4" color="text.primary" mt={2}>
          {label}
        </Typography>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={100}
          size={isMobile ? 120 : 160}
          thickness={6}
          sx={{ color: color }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection='column'
        >
          <Typography variant="h5" component="div" color="text.secondary">
            {displayValue}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {displayUnit}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default function GlobalSavings() {
  const [savings, setSavings] = useState({
    rev_co2eSavings: 0,
    rev_costSavings: 0,
    rev_energySavings: 0,
  })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const loadSavings = async () => {
      const fetchedSavings = await fetchGlobalSavings()
      setSavings(fetchedSavings)
    }

    loadSavings()
  }, [])

  return (
    <Box
      sx={{
        p: { xs: 3, md: 3 },
        mb: 4,
        maxWidth: 800,
        mx: 'auto',
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom align="center" color="primary">
        Our Cumulative Impact
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'column' }}
        justifyContent="space-around"
        alignItems="center"
        mt={-3}
      >
        <SavingsCircle 
          value={savings.rev_co2eSavings} 
          label="CO2e Savings" 
          color={theme.palette.success.main}
          unit="lbs"
        />
        <SavingsCircle 
          value={savings.rev_costSavings} 
          label="Cost Savings ($)" 
          color={theme.palette.info.main}
          unit="$"
        />
        <SavingsCircle 
          value={savings.rev_energySavings} 
          label="Energy Savings" 
          color={theme.palette.warning.main}
          unit="Wh"
        />
      </Box>
    </Box>
  )
}