import React from "react";
import chartImage from '../../../../../assets/images/pieChart.png';
import { sumHoursByOutletName, filterByDate } from "../../../../../functions/wattHours";
import { StackedBar } from "../../../../../components/newGraphLibrary/graphs";
import { GraphHeader } from "../../../../../components/graphHeader";

export default function StackedDailyUsagePresentation({
    outlets,
    wattHours,
    height,
    width
}) {
    const customDailyLabels = {
        0: 'Monday',
        1: 'Tuesday',
        2: 'Wednesday',
        3: 'Thursday',
        4: 'Friday',
        5: 'Saturday',
        6: 'Sunday'
    };
    const dailyBottomAxisLabel = (value) => customDailyLabels[value] || value;
    let dailyBarData = [];
    let graphKeys = [];
    outlets.forEach((outlet, index) => {
        graphKeys.push(outlet.name)
    });
    const today = new Date();
    const dayOfWeek = today.getDay();
    const previousMonday = new Date(today);
    previousMonday.setDate(today.getDate() - (dayOfWeek + 6) % 7); // Set to previous Monday
    const daysOfWeek = [];
    // Start from the previous Monday
    let day = new Date(previousMonday);
    for (let i = 0; i < 7; i++) {
        const year = day.getFullYear();
        const month = String(day.getMonth() + 1).padStart(2, '0');
        const date = String(day.getDate()).padStart(2, '0');
        daysOfWeek.push(`${year}-${month}-${date}`);
        // Move to the next day
        day.setDate(day.getDate() + 1);
    };
    const monday = sumHoursByOutletName(filterByDate(wattHours, daysOfWeek[0]));
    const tuesday = sumHoursByOutletName(filterByDate(wattHours, daysOfWeek[1]));
    const wednesday = sumHoursByOutletName(filterByDate(wattHours, daysOfWeek[2]));
    const thursday = sumHoursByOutletName(filterByDate(wattHours, daysOfWeek[3]));
    const friday = sumHoursByOutletName(filterByDate(wattHours, daysOfWeek[4]));
    const saturday = sumHoursByOutletName(filterByDate(wattHours, daysOfWeek[5]));
    const sunday = sumHoursByOutletName(filterByDate(wattHours, daysOfWeek[6]));
    const dailyUsageArray = [
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
    ];
    let sum = 0;
    let averageDay = [0, 0, 0, 0, 0, 0, 0];
    let day_count = 0
    dailyUsageArray.forEach((day, index) => {
        for (const outlet in day) {
            averageDay[index] += day[outlet];
            sum += day[outlet];
        };
        if(sum > 0){
            day_count += 1
        }
    });
    dailyUsageArray.forEach((day, index) => {
        const object = {
            day: `${index}`,
        };
        Object.keys(day).forEach((key, index) => {
            object[key] = day[key].toFixed(2);
        });
        dailyBarData[index] = object;

        sum += Object.values(dailyUsageArray[index]).reduce((acc, currentValue) => acc + currentValue, 0);
    });
    const averageDayAverage = (
        averageDay[0] +
        averageDay[1] +
        averageDay[2] +
        averageDay[3] +
        averageDay[4] +
        averageDay[5] +
        averageDay[6]
    ) / day_count;

    let percentChange = 0;

    if (sum !== 0) {
        return (
            <div>
                <GraphHeader percentChange={percentChange} type={'daily'} width={width}/>
                <div style={{
                    width: '100%',
                    height: width < 1400 ? width*0.29 : '410px',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '-5px',
                }}>
                    <StackedBar
                        data={dailyBarData}
                        index={'day'}
                        axisBottomFormat={dailyBottomAxisLabel}
                        average={averageDayAverage}
                        graphKeys={graphKeys}
                        height={height}
                        width={width}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div style={{
                height: width < 1400 ? '29vw' : '410px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <div style={{
                    alignItems: 'center',
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <img
                            src={chartImage}
                            alt="pie chart"
                            style={{
                                width: 'auto',
                                height: width < 1400 ? width*.175 : '250px',
                            }}
                        />
                    </div>
                    <p
                        style={{
                            fontSize: width < 1050 ? width*.025 : '27px',
                            fontFamily: 'Manrope',
                            fontWeight: '600',
                            marginTop: '2%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        No Chart Data
                    </p>
                    <p
                        style={{
                            textAlign: 'center',
                            fontFamily: 'Manrope',
                            marginTop: '1%',
                            fontSize: width < 1050 ? width*.02 : '22px',
                        }}>
                        Check back tomorrow to see daily usage.
                    </p>
                </div>
            </div>
        );
    };

};