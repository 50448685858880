import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { fetchUsersFromOrganization } from "../../functions/users";
import {
  fetchOrganizationFromID,
  updateOrganizationRate,
  updateOrganizationBill,
  fetchOrganizationIDs,
  fetchSpecificTeamOrganizationIDs,
} from "../../functions/organization";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { greyDark, green } from "../../lib/colors";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL, getMetadata } from "firebase/storage";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function OrganizationModal({
  open,
  handleClose,
  org,
  organizations,
  isModal = true,
  selectedOrgID,
  orgID,
}) {
  const [organization, setOrganization] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [rate, setRate] = useState("");
  const [billUploaded, setBillUploaded] = useState(false);
  const [allOrgs, setAllOrgs] = useState([]);
  const [file, setFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    if (isModal === false) {
      if (org === "LZqI3R6MInUuwtgtROPK") {
        fetchOrganizationIDs(setAllOrgs);
      } else {
        if(org){
          console.log('org?', org);
          fetchSpecificTeamOrganizationIDs(org, setAllOrgs);
        }
      };
    };
  }, [org]);
  let parentOrgs = []
  let filteredOrgs = allOrgs
  allOrgs.map((org) => {
    if (org.child_orgs) {
      parentOrgs.push(org)
    };
  });
  parentOrgs.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    };
    if (a.name > b.name) {
      return 1;
    };
  });

  useEffect(() => {
    if (!org) return;

    const fetchOrganizationAndUsers = async () => {
      let organization = null;

      if (isModal) {
        organization = organizations.find(
          (organization) => organization.name.stringValue === org.name
        );
        // console.log(organization);
        if (organization) {
          setOrganization(organization);
          setRate(Number(organization.rate?.doubleValue).toFixed(2));
          setBillUploaded(organization?.billUpload?.booleanValue ? organization?.billUpload?.booleanValue : false);
          try {
            setLoading(true);
            // const fetchedOrganization = await fetchOrganizationFromID(org);
            const fetchedUsers = await fetchUsersFromOrganization(
              organization.uid.stringValue
            );
            setUsers(fetchedUsers);
          } catch (error) {
            console.error("Error fetching users:", error);
          } finally {
            setLoading(false);
          };
        };
      } else {
        try {
          organization = await fetchOrganizationFromID(org);
          if (organization) {
            setOrganization(organization);
            setRate(organization.rate);
            setBillUploaded(organization.billUpload);
            try {
              setLoading(true);
              const fetchedUsers = await fetchUsersFromOrganization(org);
              setUsers(fetchedUsers);
            } catch (error) {
              console.error("Error fetching users:", error);
            } finally {
              setLoading(false);
            };
          };
        } catch (error) {
          console.error("Error fetching organization:", error);
        };
      };
    };

    fetchOrganizationAndUsers();
  }, [org, isModal, organizations]);

  const handleRateChange = (event) => {
    const value = event.target.value;
    // Allow only valid double values
    if (/^\d*\.?\d*$/.test(value)) {
      setRate(value);
    }
  };

  const handleRateSave = async () => {
    if (!organization || !rate) return;

    try {
      setLoading(true);
      await updateOrganizationRate(organization.uid, parseFloat(rate));
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating rate:", error);
      alert("Failed to update the rate.");
    } finally {
      setLoading(false);
    };
  };
  const getFileExtension = (mimeType) => {
    switch (mimeType) {
      case 'application/pdf':
        return '.pdf';
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
      default:
        return ''; // Return an empty string if the MIME type is unknown
    }
  };
  const downloadFile = async () => {
    try {
      const fileName = `electricityBills/${org.name}/${org.name} bill`;
      // Create a reference to the file you want to download
      const fileRef = ref(storage, fileName);

      const url = await getDownloadURL(fileRef);

      const fileMetadata = await getMetadata(fileRef);
      const mimeType = fileMetadata.contentType; // Retrieve the MIME type
      const fileExtension = getFileExtension(mimeType);

      console.log("File URL:", url);

      window.open(url, '_blank');
      
    } catch (error) {
      console.error("Error downloading file:", error);
    };
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);

      const fileName = `electricityBills/${organization.name}/${organization.name} bill`;
      const storageRef = ref(storage, fileName);
      setFileLoading(true);
      try {
        await uploadBytes(storageRef, selectedFile);
        console.log('File uploaded successfully');

        await updateOrganizationBill(organization.uid, setBillUploaded);
        console.log('Bill upload field updated successfully');
        setOpenDialog(true);
      } catch (error) {
        console.log('Upload failed', error);
      } finally {
        setFileLoading(false);
      };
    } else {
      alert('Only PDF, JPG, and PNG files are allowed.');
    };
  };

  if (!organization) return null;

  if (isModal) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "60%" }}>
            <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
              {organization.name.stringValue}
            </Typography>
            {organization.uid && organization.uid.stringValue && (
              <Typography sx={{ mt: 1 }}>
                Organization ID: {organization.uid.stringValue}
              </Typography>
            )}
            {organization.joinDate && organization.joinDate.stringValue && (
              <Typography sx={{ mt: 1 }}>
                Join Date: {organization.joinDate.stringValue}
              </Typography>
            )}
            {organization.rate && organization.rate.doubleValue && (
              <Typography sx={{ mt: 1 }}>
                Electricity Rate: ${organization.rate.doubleValue.toFixed(2)}
              </Typography>
            )}

            {/*organization.region && organization.region.stringValue && (
              <Typography sx={{ mt: 1 }}>
                ISO Region: {organization.region.stringValue}
              </Typography>
            )*/}
            {organization.timezone && organization.timezone.stringValue && (
              <Typography sx={{ mt: 1 }}>
                Timezone: {organization.timezone.stringValue}
              </Typography>
            )}
            {organization.zipcode && organization.zipcode.stringValue && (
              <Typography sx={{ mt: 1 }}>
                Zipcode: {organization.zipcode.stringValue}
              </Typography>
            )}
            {orgID === 'LZqI3R6MInUuwtgtROPK' && billUploaded &&
              <Button
                variant="contained"
                onClick={downloadFile}
                sx={{
                  backgroundColor: '#f6a6aa',
                  color: '#000000',
                  '&:hover': {
                    backgroundColor: '#9b060e',
                    color: '#ededed',
                  },
                  mt: 1,
                }}
                startIcon={<CloudDownloadIcon />}
              >
                {org?.name} Electricity Bill
              </Button>
            }
          </Box>
          <Box sx={{ width: "40%", maxHeight: "300px", overflowY: "auto" }}>
            <Box>
              <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
                Users
              </Typography>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <CircularProgress />
                </Box>
              ) : users.length > 0 ? (
                users.map((user, index) => (
                  <Typography key={index} sx={{ mt: 1 }}>
                    {user.email}
                  </Typography>
                ))
              ) : (
                <Typography sx={{ mt: 1 }}>No users found.</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  } else {
    return (
      <Box
        sx={{
          alignSelf: 'flex-start',
          bgcolor: "background.paper",
          p: 4,
          display: "flex",
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h6" component="h2" sx={{ fontWeight: "bold", mb: 2 }}>
            {organization.name}
          </Typography>
          {organization.uid && (
            <Typography sx={{ mb: 2 }}>
              Organization ID: {organization.uid}
            </Typography>
          )}
          {organization.joinDate && (
            <Typography sx={{ mb: 2 }}>
              Join Date: {organization.joinDate}
            </Typography>
          )}
          {/*organization.region && (
              <Typography sx={{ mb: 2 }}>
                ISO Region: {organization.region}
              </Typography>
            )*/}
          {organization.timezone && (
            <Typography sx={{ mb: 2 }}>
              Timezone: {organization.timezone}
            </Typography>
          )}
          {organization.zipcode && (
            <Typography sx={{ mb: 2 }}>
              Zipcode: {organization.zipcode}
            </Typography>
          )}
          <Box sx={{
            flexGrow: 1,
          }}>
            <Box sx={{
              display: 'flex'
            }}>
              {isEditing ? (
                <TextField
                  label="Rate"
                  variant="outlined"
                  value={rate}
                  onChange={handleRateChange}
                  sx={{
                    // flexGrow: 1,
                    mr: 2
                  }}
                />
              ) : (
                <Typography sx={{
                  // flexGrow: 1,
                  pr: 5
                }}>
                  Electricity Rate: ${rate}
                </Typography>
              )}
              <IconButton
                onClick={() => {
                  if (isEditing) {
                    handleRateSave();
                  } else {
                    setIsEditing(true);
                  }
                }}
                color={isEditing ? "primary" : "default"}
                sx={{
                  mt: -1,
                }}
              >
                {isEditing ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </Box>
          </Box>
          {!billUploaded ? 
          <Box sx={{
            marginTop: '10px',
          }}>
            <Box>
              <input
                type="file"
                accept="application/pdf,image/jpeg,image/png"
                style={{ display: 'none' }}
                id="fileInput"
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                onClick={handleButtonClick}
                sx={{
                  backgroundColor: '#f6a6aa',
                  color: '#000000',
                  '&:hover': {
                    backgroundColor: '#9b060e',
                    color: '#ededed',
                  }
                }}
                // NOTE: can switch the first icon below back to complete once fixing behavior with overview accounts
                startIcon={fileLoading ? null : (billUploaded ? <CloudUploadIcon /> : <CloudUploadIcon />)}
                disabled={fileLoading}
              >
                {fileLoading ? <CircularProgress size={24} color="inherit" /> : 'Electricity Bill'}
              </Button>
            </Box>
            {/* upload complete modal */}
            <Dialog open={openDialog} close={handleClose}>
              <DialogTitle>Upload Complete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your file has been successfully uploaded.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          :
          <Box>
            <Button
            variant="contained"
            sx={{
              backgroundColor: '#f6a6aa',
              color: '#000000',
              marginTop:'10px',
            }}
            startIcon={<CloudDoneIcon />}
            >
              Bill Uploaded
            </Button>
        </Box>
        }
        </Box>
      </Box>
    );
  };
};
