// import firebase from '@react-native-firebase/app';
import { FirebaseApp } from "firebase/app";
import firebase from "firebase/app";
// import firestore from '@react-native-firebase/firestore';
import {
  Firestore,
  getDoc,
  query,
  collection,
  where,
  getDocs,
  collectionGroup,
} from "firebase/firestore";
import db from "../../firebase";
import { doc } from "firebase/firestore";

import {
  LOGIN_INIT,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  CLEAR_LOGOUT_ERROR,
} from "../../constants/action-types";
import { createUser } from "../user";
// import '@react-native-firebase/auth';
// import '@react-native-firebase/firestore';
import { auth, uid } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
// import { GoogleSignin } from '@react-native-google-signin/google-signin';
// import { appleAuth } from '@invertase/react-native-apple-authentication';
import { hydrate } from "./shared";
import buildAppState from "../../lib/state-utils";
import { element } from "prop-types";
// import { testRef } from '../../lib/state-utils';
export const loginInit = () => ({ type: LOGIN_INIT });

export const loginError = (errorMessage) => ({
  type: LOGIN_ERROR,
  payload: { error: errorMessage },
});

export const loginSuccess = () => ({ type: LOGIN_SUCCESS });

export const clearAuthError = () => ({ type: CLEAR_LOGOUT_ERROR });
//need to ensure app state is populated prior to navigating to home page,
export function login(email, password, sign_in_method) {
  return async function (dispatch, getState) {
    dispatch(loginInit());
    try {
      let authRecord = null;

      // google sign-in
      // if (sign_in_method == "google") {
      //     console.log('google sign-in')

      //     GoogleSignin.configure();

      //     // Get the users ID token
      //     const { info } = await GoogleSignin.signIn();
      //     const { idToken, accessToken } = await GoogleSignin.getTokens();

      //     // Create a Google credential with the token
      //     const googleCredential = firebase.auth.GoogleAuthProvider.credential(idToken, accessToken);

      //     // Sign-in the user with the credential
      //     authRecord = await firebase.auth().signInWithCredential(googleCredential);
      // }
      // apple sign-in
      // else if (sign_in_method == "apple") {
      //     console.log('apple sign-in')
      //     // Start the sign-in request
      //     const appleAuthRequestResponse = await appleAuth.performRequest({
      //         requestedOperation: appleAuth.Operation.LOGIN,
      //         requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
      //     });

      //     // Ensure Apple returned a user identityToken
      //     if (!appleAuthRequestResponse.identityToken) {
      //         throw 'Apple Sign-In failed - no identify token returned';
      //     }

      //     const credentialState = await appleAuth.getCredentialStateForUser(appleAuthRequestResponse.user);

      //     // use credentialState response to ensure the user is authenticated
      //     if (credentialState === appleAuth.State.AUTHORIZED) {
      //         // user is authenticated
      //         // Create a Firebase credential from the response
      //         const { identityToken, nonce } = appleAuthRequestResponse;
      //         const appleCredential = firebase.auth.AppleAuthProvider.credential(identityToken, nonce);

      //         // Sign the user in with the credential
      //         authRecord = await firebase.auth().signInWithCredential(appleCredential);
      //     }
      // }
      // email sign-in

      // console.log('email sign-in');
      //NOTE: ONly part that is needed
      authRecord = await signInWithEmailAndPassword(auth, email, password).then(
        console.log("signed in with: ", email)
      );
      window.localStorage.setItem("approvedSignIn", JSON.stringify(true));
      let userID = authRecord.user.uid;

      //NOTE: old login function
      // const userRef = doc(db, 'users', authRecord.user.uid);
      // const userSnapshot = await getDoc(userRef);
      // const organizationID = await getDocs(collectionGroup(db, "organizations"))
      // let inOrganization = false;
      // organizationID.docs.forEach(organization => {
      //     // console.log('organization users', organization._document.data.value.mapValue.fields.users.arrayValue.values);
      //     // console.log('organization users simple', organization._document.data.value.mapValue.fields.users.arrayValue.values.map(obj => obj.stringValue));

      //     if (inOrganization) {
      //         return;
      //     };
      //     organization._document.data.value.mapValue.fields.users.arrayValue.values.forEach(user => {
      //         if (user.stringValue == userID) {
      //             console.log('user includes', user.stringValue, userID);
      //             userID = organization.id;
      //             inOrganization = true;
      //         }
      //     });
      // });
      //NOTE: don't actually need anything in the login
      // const orgIDQ = query(collection(db, 'organizations'), where('users', 'array-contains', userID));
      // const orgIDSnapshot = await getDocs(orgIDQ);
      // orgIDSnapshot.forEach((doc) => {
      //     userID = doc.id;
      // });

      // // if there isn't a user document in the firebase
      // if (!userSnapshot.exists) {
      //     // if social sign in, we simply register a new document
      //     //no social sign in at the moment
      //     // if (sign_in_method != "email") {
      //     //     const newRef = await dispatch(createUser({ email, password, devID: '', sign_in_method: sign_in_method }));
      //     //     userSnapshot = await userRef.get();
      //     // }
      //     // if email sign in, user must register their email from register page

      //     dispatch(loginError('user-not-found'));

      // }
      //not sure this is being used anymore
      // // build app state based on old or newly created user document
      //likely reason the loading can take a long time
      // if (userSnapshot.exists || (!userSnapshot.exists && sign_in_method !== 'email')) {
      //     const outletsSnapshot = await getDocs(collectionGroup(db, "Outlets"));
      //     const groupsSnapshot = await getDocs(collectionGroup(db, "Groups"));
      //     const schedulesSnapshot = await getDocs(collectionGroup(db, "Schedules"));
      //     const timersSnapshot = await getDocs(collectionGroup(db, "Timers"));
      //     const missionsSnapshot = await getDocs(collectionGroup(db, "Missions"));
      //     const globalSnapshot = await getDocs(collectionGroup(db, 'global'));
      //     const wattHoursYears = await getDocs(collectionGroup(db, 'WattHours'));
      //     const wattHoursMonths = await getDocs(collectionGroup(db, 'Months'));
      //     const wattHoursWeeks = await getDocs(collectionGroup(db, 'Weeks'));
      //     const wattHoursDays = await getDocs(collectionGroup(db, 'Days'));

      //     const appState = buildAppState({
      //         userSnapshot,
      //         outletsSnapshot,
      //         groupsSnapshot,
      //         schedulesSnapshot,
      //         timersSnapshot,
      //         missionsSnapshot,
      //         globalSnapshot,
      //         userID,
      //         wattHoursYears,
      //         wattHoursMonths,
      //         wattHoursWeeks,
      //         wattHoursDays,
      //     });

      //     dispatch(hydrate(appState));
      // };
    } catch (err) {
      if (err.code === "auth/multi-factor-auth-required") {
        throw err
      } else if (err.code==="auth/network-request-failed"){
        console.log(err)
      } 
      else {
        console.error(err);
        dispatch(loginError(err.message));
      }
    }
  };
}
