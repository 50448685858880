import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, clearAuthError } from "../../../actions/auth/login";
//might need to update this import
// import { useNavigation } from '@react-navigation/native';
import { useNavigate } from "react-router";
import Login from "../loginPresentational";
import { Alert } from "react-native";
import { getMultiFactorResolver } from "@firebase/auth";
import { auth } from "../../../firebase";

export default function LoginWired() {
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(clearAuthError());
  };

  function handleLogin(email, password, sign_in_method) {
    setLoading(true);

    dispatch(login(email, password, sign_in_method))
      .then(() => {
        setLoading(false);
        console.log("User signed in successfully.");
      })
      .catch((error) => {
        setLoading(false);

        if (error.code === "auth/multi-factor-auth-required") {
          window.localStorage.setItem("approvedSignIn", JSON.stringify(true));
          const resolver = getMultiFactorResolver(auth, error)
          window.resolver = resolver;
          navigate("/login/otp");
        } else {
          console.error("Error during login:", error);
        }
      });
  }
  function handleError(errorMessage) {
    const startIndex = String(errorMessage).indexOf("auth");
    const endIndex = String(errorMessage).indexOf("]");
    const err = String(errorMessage).substring(startIndex + 5, endIndex);
    let message = err;
    if (err === "invalid-email" || err === "user-not-found") {
      message = "A user with that email was not found, please try again";
      setOpen(true);
    }
    if (err === "invalid-password" || err === "wrong-password") {
      message =
        "The password entered for this user was incorrect, please try again";
      setOpen(true);
    } else {
      message = "Sign in error";
      // setOpen(true);
    }
    // Alert.alert(
    //     'Error',
    //     message,
    //     [
    //         {
    //             text: 'OK',
    //             onPress: () => {
    //                 dispatch(clearAuthError());
    //             },
    //         },
    //     ],
    //     { cancelable: false },
    // );
  }
  useEffect(() => {
    if (error) {
      setLoading(false);
      handleError(error);
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  return (
    <Login
      onLogin={handleLogin}
      loading={loading}
      setLoading={setLoading}
      navigate={navigate}
      open={open}
      handleClose={handleClose}
    />
  );
}
