import React, { useEffect, useState } from "react";
import { fetchOrgSchedules } from "../../../functions/scheduleTable";
import OrganizationPicker from "../../../components/orgPicker";
import { Box, Button, Typography } from "@mui/material";
import HoursTable from "../../../components/hoursTable";
import { fetchOutlets } from "../../../functions/outletList";
import { fetchIsOverviewAccount } from "../../../functions/organization";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { green, greyUltraDark, orange } from "../../../lib/colors";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function ScheduleTable({
  selectedOrgID,
  outlets,
}) {
  const orgID = localStorage.getItem("orgID");
  // const [selectedOrgID, setSelectedOrgID] = useState(
  //   localStorage.getItem("selectedOrgID") || ""
  // );
  const [selectedDay, setSelectedDay] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [timezoneConversion, setTimezoneConversion] = useState(0);
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC"
  );
  const [selectedDate, setSelectedDate] = useState(new Date());

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

  useEffect(() => {
    const getTodayDay = () => {
      const date = new Date();
      return date.toLocaleDateString("en-US", { weekday: "short", timeZone: timezone });
    };

    setSelectedDay(getTodayDay());
  }, []);

  useEffect(() => {
    if (selectedOrgID !== "") {
      //fetchOutlets(selectedOrgID, timezone, setOutlets, setOutletsLoading);
      fetchOrgSchedules(selectedOrgID, setSchedules);
    } else {
      fetchOrgSchedules(orgID, setSchedules);
      //fetchOutlets(orgID, timezone, setOutlets, setOutletsLoading);
    };
  }, [orgID, selectedOrgID]);

  const handleNextDay = () => {
    const currentDayIndex = daysOfWeek.indexOf(selectedDay);
    const nextDayIndex = (currentDayIndex + 1) % daysOfWeek.length;

    const dateOneDayAfter = new Date(selectedDate.getTime() + oneDayInMs);

    setSelectedDate(dateOneDayAfter);

    setSelectedDay(daysOfWeek[nextDayIndex]);
  };

  const handlePreviousDay = () => {
    const currentDayIndex = daysOfWeek.indexOf(selectedDay);
    const previousDayIndex =
      (currentDayIndex - 1 + daysOfWeek.length) % daysOfWeek.length;

    const dateOneDayBefore = new Date(selectedDate.getTime() - oneDayInMs);

    setSelectedDate(dateOneDayBefore);
    setSelectedDay(daysOfWeek[previousDayIndex]);
  };

  const currentDayIndex = daysOfWeek.indexOf(selectedDay);
  const nextDayName = daysOfWeek[(currentDayIndex + 1) % daysOfWeek.length];
  const previousDayName = daysOfWeek[(currentDayIndex - 1 + daysOfWeek.length) % daysOfWeek.length];

  const changeDate = (value) =>{
    console.log(dayjs(value).format('ddd'))
    setSelectedDay(dayjs(value).format('ddd'));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "90vw",
          marginTop: 2,
          //backgroundColor:'red'
        }}
      >
        <Box sx={{width:'33%'}}></Box>
        <Box sx={{width:'37%', display:'flex',alignItems:'center',justifyContent: 'center',}}>
          <ArrowBackIosIcon onClick={handlePreviousDay} sx={{marginRight:'20px'}}/>
          <Typography fontSize='17px' fontWeight='1000' fontFamily={"manrope"}>{selectedDay}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker sx={{width:'160px', height:'57px', 
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' },
            "& .MuiOutlinedInput-input": {
              fontFamily: 'manrope',
              fontSize: '17px',
              fontWeight:'1000'
            }
            }} 
            value={dayjs(selectedDate.toLocaleDateString("en-US", {timeZone: timezone,}))}
            onChange={(event) => changeDate(event.$d)}
            defaultValue={dayjs(selectedDate.toLocaleDateString("en-US", {timeZone: timezone,}))} />
            
          </LocalizationProvider>
          <ArrowForwardIosIcon onClick={handleNextDay} sx={{marginLeft:'20px'}}/>
        </Box>
        <Box sx={{width:'30%', display:'flex', alignItems:'center',justifyContent: 'flex-end'}}>
          <Box sx={{width:'15px', height:'15px', borderRadius: '4px', backgroundColor:green, marginRight:'5px'}}></Box>
          <div style={{fontSize:'12.5px', fontFamily:'manrope', marginRight:'15px'}}>Appliances On</div>
          <Box sx={{width:'12.5px', height:'15px', borderRadius: '4px', backgroundColor:greyUltraDark, marginRight:'5px'}}></Box>
          <div style={{fontSize:'12.5px', fontFamily:'manrope'}}>Appliances Off</div>
        </Box>
      </Box>
      <Box
        sx={{
          width: "90vw",
        }}
      >
        <HoursTable schedules={schedules} selectedDay={selectedDay} outlets={outlets}/>
      </Box>
    </Box>
  );
};
