import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Grid,
  Alert, // Import Alert from Material UI
} from "@mui/material";
import UsageDashboardContainer from "../../pages/home/usageDashboardContainer";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";
import { green, greyDark, orange } from "../../lib/colors";
import { storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import imageCompression from "browser-image-compression";
import { submitNameEdit, submitPhotoEdit, deletePhotoEdit, toggleOutletStatus } from "../../functions/outletList";
import CloseIcon from '@mui/icons-material/Close';

function statusToBool(status) {
  return status === "On";
};

export default function OutletModal({
  isOpen,
  handleClose,
  outlet,
  rate,
  selectOnPress,
  width,
  height,
  outlets,
  setOutlets,
  outletData,
  name,
  setOutletName,
  prodType,
  setProdType,
  productTypes,
}) {
  const [outletNameModal, setOutletNameModal] = useState(name)
  const [oldName, setOldName] = useState(name)//useState(outletName)
  const [wattsLoading, setWattsLoading] = useState(true);
  const [wattHours, setWattHours] = useState([]);
  const [outletAverageUsage, setOutletAverageUsage] = useState([]);
  const [orgID, setOrgID] = useState(localStorage.getItem("orgID"));
  const [dataFetched, setDataFetched] = useState(false);
  const [status, setStatus] = useState(outletData.status);
  const [selectedOrgID, setSelectedOrgID] = useState(
    localStorage.getItem("selectedOrgID")
  );

  const [outletUrl, setOutletUrl] = useState();
  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility
  const [deleteClick, setDeleteClick] = useState(false);
  const index = outlets.findIndex((obj) => obj.id === outlet.id);

  const toggleOn = async () => {
    toggleOutletStatus("On", outlet.orgID, outlet.id, outlet.docID);
    console.log("On");
    outlets[index].status = "On";
    setStatus("On");
    //setOutlets(outlets);
  };
  const toggleOff = async () => {
    toggleOutletStatus("Off", outlet.orgID, outlet.id, outlet.docID);
    console.log("Off")
    setStatus("Off");
  };

  useEffect(() => {
    if (isOpen && !dataFetched) {
      if (selectedOrgID) {
        fetchWattHours(selectedOrgID, setWattHours, setWattsLoading, outlet.id);
        fetchAverageUsage(selectedOrgID, setOutletAverageUsage, outlet.id);
      } else {
        fetchWattHours(orgID, setWattHours, setWattsLoading, outlet.id);
        fetchAverageUsage(orgID, setOutletAverageUsage, outlet.id);
      }
      setDataFetched(true);
    }
  }, [isOpen, dataFetched, orgID, selectedOrgID, outlet.id]);

  const handleNameChange = (event) => {
    //setOutletName(event.target.value)
    setOutletNameModal(event.target.value)
  };

  const handleSaveClick = async (event) => {
    console.log(oldName)
    console.log(outletNameModal)
    if (oldName == outletNameModal) {
      console.log("yoooo");
      handleClose(event);
    }
    else {
      let success = await submitNameEdit(
        outlet.orgID,
        outlet.id,
        outlet.docID,
        outletNameModal
      );
      if (!success) {
        setShowAlert(true);
        setOutletName(name);
        setOutletNameModal(name);
      } else {
        setShowAlert(false);
        setOldName(outletNameModal);
        setOutletName(outletNameModal);
        setOutlets((prevOutlets) =>
          prevOutlets.map((o) => (o.id === outlet.id ? { ...o, name: outletNameModal } : o))
        );
        handleClose(event);
      }
    }
  };

  const handleApplianceChange = (event) => {
    const productType = event.target.value;
    setProdType(productType);
    selectOnPress(event);
  };

  const fileInputRef = useRef(null);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    if (file) {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      });
      const storageRef = ref(storage, fileName);
      try {
        const snapshot = await uploadBytes(storageRef, compressedFile);
        console.log('Upload file', snapshot);
        const downloadUrl = await getDownloadURL(snapshot.ref);
        setOutletUrl(downloadUrl);
        console.log('file at', downloadUrl);
        await submitPhotoEdit(outlet.docID);
      } catch (error) {
        console.error('error uploading', error);
      };
    };
  };

  const handleDeleteClick = async () => {
    setDeleteClick(!deleteClick)
  };
  const handleDelete = async (event) => {
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    const storageRef = ref(storage, fileName);
    // Delete the file
    await deletePhotoEdit(outlet.docID);
    deleteObject(storageRef).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
    setOutletUrl();
    setDeleteClick(false)
  };

  useEffect(() => {
    if (isOpen) {
      // console.log('outlet', outlet);
      const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
      const fetchImageUrl = async () => {
        try {
          const fileRef = ref(storage, fileName);
          const url = await getDownloadURL(fileRef);
          setOutletUrl(url);
          // console.log('url', url);
        } catch (error) {
          console.error("Error fetching image URL:", error);
        };
      };
      setOutletNameModal(name);
      setOldName(name);
      if(outletData.imageURl==true){
        fetchImageUrl();
      }
    };
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason != 'backdropClick' && reason != 'escapeKeyDown') {
          handleClose(event);
          setDataFetched(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          height: "90%",
          bgcolor: '#F2F4F8',
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {outlet.name} Details
            </Typography>
            {orgID === 'LZqI3R6MInUuwtgtROPK' &&
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Outlet ID: {outlet.device}
              </Typography>
            }
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <IconButton onClick={handleSaveClick}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ alignSelf: 'center', marginTop: '10px' }}>
          {outletUrl ?
            <Box style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '400px',
              height: '350px',
            }}>
              <img
                src={outletUrl}
                alt="Photo of Outlet"
                style={{
                  maxWidth: '300px',
                  maxHeight: '300px',
                }}
              />
              <Box sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                marginTop: '10px'
              }}>
                <Button onClick={handleUploadClick} variant="outlined">
                  Change Image
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <Button onClick={handleDeleteClick} variant="contained" style={{ backgroundColor: 'red' }}>
                  Delete Image
                </Button>
              </Box>
              <Modal open={deleteClick}
                onClose={(event) => {
                  handleDeleteClick(event);
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "40%",
                    height: "8%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflow: "auto",
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center'
                  }}
                >
                  <div>Confirm you would like to delete the image</div>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", gap: 2, marginTop: '20px' }}
                  >
                    <Button onClick={handleDeleteClick} variant="outlined">
                      Cancel
                    </Button>
                    <Button onClick={handleDelete} variant="contained" style={{ backgroundColor: 'red' }}>
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
            :
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '400px',
              height: '350px',
            }}>
              <button
                style={{
                  width: '300px',
                  height: '300px',
                  borderWidth: '3px',
                  borderStyle: 'dashed',
                  borderColor: orange,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  fontFamily: 'Manrope',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={handleUploadClick}
              >
                Upload Image
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </Box>
          }
        </Box>

        {showAlert && (
          <Alert
            severity="warning"
            onClose={() => setShowAlert(false)}
            style={{ marginTop: 16 }}
          >
            The outlet name already exists. Please choose a different name.
          </Alert>
        )}

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px', // Space between buttons
          marginTop: '20px',
        }}>
          <button
            onClick={toggleOn}
            style={{
              width: '100px', // Set fixed width for buttons
              height: '100px', // Set fixed height for buttons
              border: 'none',
              borderRadius: '50%', // Makes the button circular
              color: 'white',
              fontSize: '16px',
              cursor: 'pointer',
              transition: 'opacity 0.3s',
              backgroundColor: green,
              opacity: status == "On" ? 1 : 0.2,
            }}
          >
            On
          </button>
          <button
            onClick={toggleOff}
            style={{
              width: '100px', // Set fixed width for buttons
              height: '100px', // Set fixed height for buttons
              border: 'none',
              borderRadius: '50%', // Makes the button circular
              color: 'white',
              fontSize: '16px',
              cursor: 'pointer',
              transition: 'opacity 0.3s',
              backgroundColor: 'red',
              opacity: status == "On" ? 0.2 : 1,
            }}
          >
            Off
          </button>
        </div>

        <Box sx={{ mt: 2 }}>
          <TextField
            label="Edit Name"
            //defaultValue={outlet.name}
            value={outletNameModal}
            onChange={handleNameChange}
            fullWidth
            margin="dense"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="product-type-label">Select Product Type</InputLabel>
            <Select
              labelId="product-type-label"
              value={prodType === "" ? "N/A" : prodType}
              onChange={handleApplianceChange}
              label="Select Product Type"
              sx={{
                color:
                  prodType === "N/A" || prodType === ""
                    ? "orange"
                    : "inherit",
              }}
            >
              {productTypes.map((type, index) => (
                <MenuItem
                  key={index}
                  value={type}
                  style={{
                    color: type === "N/A" ? "orange" : "inherit",
                  }}
                >
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box style={{ marginTop: "2%", display: 'flex', justifyContent: 'center' }}>
          <UsageDashboardContainer
            orgID={selectedOrgID ? selectedOrgID : orgID}
            outlets={outlets}
            outletAverageUsage={outletAverageUsage}
            wattHours={wattHours}
            height={height}
            width={width < 1400 ? width * 0.85 : width * 0.735}
            singleOutlet={true}
            outlet={outlet}
            rate={rate}
          />
        </Box>
      </Box>
    </Modal >
  );
}
