import React, { useState, useEffect } from "react";
import StackedWeeklyUsagePresentational from "./stackedWeeklyUsagePresentational";
import StackedDailyUsagePresentational from "./stackedDailyUsagePresentational";
import {
  leftUsageDisplayPress,
  rightUsageDisplayPress,
} from "../../../../functions/outletList";
import LocationBubbles from "../../../../components/locationBubble";
import IonIcon from "@reacticons/ionicons";
import {
  green,
  greyDark,
  greyLight,
  greyUltraLight,
} from "../../../../lib/colors";
import {
  Box,
} from "@mui/material";

export default function StackedUsageContainer({
  outlets,
  wattHours,
  height,
  width,
  selectedApplianceType = null,
  handleApplianceTypeChange = null,
  prodTypes = null,
}) {
  const [stackedUsageDisplayIndex, setStackedUsageDisplayIndex] = useState(0);
  //needs to be updated for adding additional usage graph display options
  const stackedUsageDisplayLength = 2;

  const handleLeftUsageDisplayPress = () => {
    leftUsageDisplayPress(
      stackedUsageDisplayIndex,
      stackedUsageDisplayLength,
      setStackedUsageDisplayIndex
    );
  };
  const handleRightUsageDisplayPress = () => {
    rightUsageDisplayPress(
      stackedUsageDisplayIndex,
      stackedUsageDisplayLength,
      setStackedUsageDisplayIndex
    );
  };

  const stackedUsagePresentationalStyle = {
    backgroundColor: 'white',//greyLight,
    borderRadius: "15px",
    //marginBottom: '1%',
    display: "flex",
    flexDirection: "column",
    //justifyContent: 'center',
    alignItems: "center",
    width: "99%",
    height: "100%", //width < 1400 ? '46.5vw' : '650px',
    overflow: "auto",
  };

  return (
    <div style={stackedUsagePresentationalStyle}>
      {stackedUsageDisplayIndex === 0 && (
        <div style={{ width: "100%", height: "87.5%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontWeight: "600",
                fontSize: width < 1000 ? width*0.02 : "20px",
                fontFamily: "manrope",
                margin: "2% 3% 0 3%", // Adjust margins to balance alignment
              }}
            >
              Weekly Usage
            </p>
          </Box>

          <div
            style={{
              width: "98%",
              marginLeft: "1%",
              alignSelf: "center",
            }}
          >
            <StackedWeeklyUsagePresentational
              outlets={outlets}
              wattHours={wattHours}
              height={height}
              width={width}
            />
          </div>
        </div>
      )}
      {stackedUsageDisplayIndex === 1 && (
        <div style={{ width: "100%", height: "87.5%",}}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontWeight: "600",
                fontSize: width < 1000 ? width*0.02 : "20px",
                fontFamily: "manrope",
                margin: "2% 3% 0 3%", // Adjust margins to balance alignment
              }}
            >
              Daily Usage
            </p>
          </Box>
          <div
            style={{
              width: "98%",
              marginLeft: "1%",
              alignSelf: "center",
            }}
          >
            <StackedDailyUsagePresentational
              outlets={outlets}
              wattHours={wattHours}
              height={height}
              width={width}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "12.5%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            //marginTop: '-1%',
            height: "65%",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <IonIcon
            name="arrow-back-circle-outline"
            style={{
              width: width < 1400 ? width*0.035 : "47.5px",
              cursor: "pointer",
              //marginTop: '-0.4vh'
            }}
            onClick={handleLeftUsageDisplayPress}
          />
          <IonIcon
            name="arrow-forward-circle-outline"
            style={{
              width: width < 1400 ? width*0.035 : "47.5px",
              cursor: "pointer",
              //marginTop: '-0.4vh'
            }}
            onClick={handleRightUsageDisplayPress}
          />
        </div>
        <div
          style={{
            height: "35%",
            //marginTop: '1%'
          }}
        >
          <LocationBubbles
            activeIndex={stackedUsageDisplayIndex}
            displayLength={stackedUsageDisplayLength}
            width={width}
          />
        </div>
      </div>
    </div>
  );
}
