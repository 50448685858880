import React, { useState } from "react";
import {
  Button,
  TextField,
  Alert,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { registerNewUser, addUserToOrganization } from "../../functions/users";
import { fetchOrganizationFromID } from "../../functions/organization";
import { getAuth, createUserWithEmailAndPassword, RecaptchaVerifier, sendEmailVerification, PhoneAuthProvider, linkWithCredential } from "firebase/auth";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { blue, green } from "../../lib/colors";
import { ToastContainer, toast } from "react-toastify";

const RegisterScreen = () => {
  const [orgId, setOrgId] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [orgFound, setOrgFound] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneError, setPhoneError] = useState(""); 
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false); 
  // const [recaptchaVerified, setRecaptchaVerified] = useState(false); 
  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailValid(isValidEmail(newEmail));
  };

  const isValidPassword = (password) => {
    if (password !== "") {
      return (
        password.length >= 8 &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /\d/.test(password)
      );
    } else {
      return true;
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!isValidPassword(newPassword)) {
      // setAlert({
      //   show: true,
      //   message:
      //     "Password must be at least 8 characters long and include a number, a lowercase and an uppercase letter.",
      //   severity: "error",
      // });
    } else {
      setAlert({ show: false, message: "", severity: "" });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    // Allow only numbers
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }

    // Check if the phone number is at least 10 digits
    if (value.length < 10) {
      setPhoneError("Phone number must be at least 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleOrgSearch = async () => {
    try {
      const orgDoc = await fetchOrganizationFromID(orgId);
      if (!orgDoc) {
        setAlert({
          show: true,
          message: "Organization not found.",
          severity: "error",
        });
        setOrgFound(false);
      } else {
        setAlert({
          show: true,
          message: `Organization found: ${orgDoc.name}`,
          severity: "success",
        });
        setOrgFound(true);
      }
    } catch (error) {
      setAlert({
        show: true,
        message: "Error searching organization.",
        severity: "error",
      });
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;
  
    if (
      !orgFound ||
      !emailValid ||
      !isValidPassword(password) ||
      password === "" ||
      password === null ||
      phoneNumber.length < 10
    ) {
      setAlert({
        show: true,
        message: "Please ensure all fields are correctly filled out.",
        severity: "error",
      });
      return;
    }
  
    const auth = getAuth();
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // Change this to the real domain url
      await sendEmailVerification(user, { url: "http://app.revert-tech.com/otp" }); //http://127.0.0.1:3000/otp for testing locally
  
      const userdata = {
        email: email,
        phone: fullPhoneNumber,
        userId: user.uid,
        orgID: orgId,
        password: password
      };
  
      window.localStorage.setItem("user", JSON.stringify(userdata));
      toast.success("Email verification sent. Please check your inbox.");
      setIsOtpSent(true)

      console.log("USER REGISTRATION: ", user)
  
      setAlert({
        show: true,
        message: "Account created. Please verify your email and enter the OTP sent to your phone.",
        severity: "success",
      });
  
    } catch (error) {
      console.error("Error during registration:", error);
      let errorMessage = "Error during registration.";
  
      if (error.code === "auth/email-already-in-use") {
        errorMessage = "The email address is already in use by another account.";
      } else if (error.code === "auth/weak-password") {
        errorMessage = "The password is too weak.";
      } else if (error.code === "auth/invalid-phone-number") {
        errorMessage = "The phone number is invalid.";
      } else if (error.code === "auth/too-many-requests") {
        errorMessage = "Too many requests. Please try again later.";
      }
  
      setAlert({
        show: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "500px",
        }}
      >
        <img
          src={logo}
          alt="ReVert Logo"
          style={{
            width: "400px",
            height: "auto",
            display: "block",
            marginTop: "2.5%",
            marginBottom: "10%",
          }}
        />
        <Typography
          variant="h4"
          sx={{ fontFamily: "Manrope", fontWeight: "250" }}
        >
          Sign Up
        </Typography>
        <TextField
          label="Organization ID"
          value={orgId}
          onChange={(e) => setOrgId(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ fontFamily: "Manrope", fontWeight: "250" }}
          InputLabelProps={{
            style: {
              fontFamily: "Manrope",
              fontWeight: 250,
            },
          }}
          disabled={orgFound}
        />
        <Button
          variant="contained"
          onClick={handleOrgSearch}
          fullWidth
          disabled={orgFound}
          style={{ backgroundColor: blue }}
        >
          Search Organization
        </Button>

        {alert.show && (
          <Alert severity={alert.severity} sx={{ mt: 2 }}>
            {alert.message}
          </Alert>
        )}

        {orgFound && (
          <>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
              required
              disabled={isOtpSent}
              sx={{
                fontFamily: "Manrope",
                fontWeight: "250",
                color: "greyUltraDark",
              }}
              InputLabelProps={{
                style: {
                  fontFamily: "Manrope",
                  fontWeight: 250,
                  color: "#A9A9A9",
                },
              }}
              error={!emailValid && email.length > 0}
              helperText={
                !emailValid && email.length > 0
                  ? "Please enter a valid email address"
                  : ""
              }
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              required
              disabled={isOtpSent}
              sx={{ fontFamily: "Manrope", fontWeight: "250" }}
              InputLabelProps={{
                style: {
                  fontFamily: "Manrope",
                  fontWeight: 250,
                },
              }}
              error={!isValidPassword(password)}
              helperText={
                !isValidPassword(password)
                  ? "Password must be at least 8 characters long and include a number, a lowercase and an uppercase letter."
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: "flex", width: "100%", gap: 1, marginY: 2 }}>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  displayEmpty
                  disabled={isOtpSent}
                >
                  <MenuItem value="+54">+54 (Argentina)</MenuItem>
                  <MenuItem value="+61">+61 (Australia)</MenuItem>
                  <MenuItem value="+32">+32 (Belgium)</MenuItem>
                  <MenuItem value="+55">+55 (Brazil)</MenuItem>
                  <MenuItem value="+1">+1 (Canada)</MenuItem>
                  <MenuItem value="+56">+56 (Chile)</MenuItem>
                  <MenuItem value="+86">+86 (China)</MenuItem>
                  <MenuItem value="+57">+57 (Colombia)</MenuItem>
                  <MenuItem value="+20">+20 (Egypt)</MenuItem>
                  <MenuItem value="+593">+593 (Ecuador)</MenuItem>
                  <MenuItem value="+372">+372 (Estonia)</MenuItem>
                  <MenuItem value="+358">+358 (Finland)</MenuItem>
                  <MenuItem value="+33">+33 (France)</MenuItem>
                  <MenuItem value="+49">+49 (Germany)</MenuItem>
                  <MenuItem value="+233">+233 (Ghana)</MenuItem>
                  <MenuItem value="+30">+30 (Greece)</MenuItem>
                  <MenuItem value="+502">+502 (Guatemala)</MenuItem>
                  <MenuItem value="+91">+91 (India)</MenuItem>
                  <MenuItem value="+62">+62 (Indonesia)</MenuItem>
                  <MenuItem value="+353">+353 (Ireland)</MenuItem>
                  <MenuItem value="+972">+972 (Israel)</MenuItem>
                  <MenuItem value="+39">+39 (Italy)</MenuItem>
                  <MenuItem value="+81">+81 (Japan)</MenuItem>
                  <MenuItem value="+60">+60 (Malaysia)</MenuItem>
                  <MenuItem value="+52">+52 (Mexico)</MenuItem>
                  <MenuItem value="+234">+234 (Nigeria)</MenuItem>
                  <MenuItem value="+47">+47 (Norway)</MenuItem>
                  <MenuItem value="+92">+92 (Pakistan)</MenuItem>
                  <MenuItem value="+51">+51 (Peru)</MenuItem>
                  <MenuItem value="+63">+63 (Philippines)</MenuItem>
                  <MenuItem value="+48">+48 (Poland)</MenuItem>
                  <MenuItem value="+351">+351 (Portugal)</MenuItem>
                  <MenuItem value="+7">+7 (Russia)</MenuItem>
                  <MenuItem value="+65">+65 (Singapore)</MenuItem>
                  <MenuItem value="+27">+27 (South Africa)</MenuItem>
                  <MenuItem value="+82">+82 (South Korea)</MenuItem>
                  <MenuItem value="+34">+34 (Spain)</MenuItem>
                  <MenuItem value="+46">+46 (Sweden)</MenuItem>
                  <MenuItem value="+41">+41 (Switzerland)</MenuItem>
                  <MenuItem value="+66">+66 (Thailand)</MenuItem>
                  <MenuItem value="+90">+90 (Turkey)</MenuItem>
                  <MenuItem value="+44">+44 (United Kingdom)</MenuItem>
                  <MenuItem value="+1">+1 (USA)</MenuItem>
                  <MenuItem value="+58">+58 (Venezuela)</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                fullWidth
                required
                error={!!phoneError}
                helperText={phoneError||"We ask for your phone number to enable MFA"}
                InputLabelProps={{
                  style: {
                    fontFamily: "Manrope",
                    fontWeight: 250,
                  },
                }}
                disabled={isOtpSent}
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              disabled={
                !orgFound ||
                !emailValid ||
                !isValidPassword(password) ||
                password === "" ||
                password === null ||
                phoneNumber.length < 10 ||
                isOtpSent
              }
              style={{ backgroundColor: green }}
            >
              Register
            </Button>
            {/* {isOtpSent && (
              <>
                <TextField
                  label="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Button
                  variant="contained"
                  onClick={handleOtpVerification}
                  fullWidth
                >
                  Verify OTP
                </Button>
              </>
            )} */}

            <div id="recaptcha-container"></div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default RegisterScreen;
