import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { fetchOutlets } from "../../functions/outletList";
import Header from '../../components/header';
import {useLocation} from 'react-router-dom';
import ScheduleTable from './scheduleNewView'

export default function ScheduleScreen() {
    const [user, waiting, error] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();
    const orgID = localStorage.getItem("orgID");
    const [selectedOrgID, setSelectedOrgID] = useState(
        localStorage.getItem("selectedOrgID") || ""
    );
    const [outlets, setOutlets] = useState(
        JSON.parse(localStorage.getItem("outlets"))
      );
    const [rate, setRate] = useState(localStorage.getItem("rate") || "0.16");
    const [timezone, setTimezone] = useState(
        localStorage.getItem("timezone") || "UTC"
    );
    const [isOverviewAccount, setIsOverviewAccount] = useState(localStorage.getItem("isOverviewAccount") || false);
    const [outletsLoading, setOutletsLoading] = useState(true);
    const [prodTypes, setProdTypes] = useState([]);
    
    // console.log(location.state);
    useEffect(() => {
        if (waiting) {
            // maybe trigger a loading screen
            return;
        }
        if (!user) navigate("/login");
    }, [user, waiting]);

    // gets information upon orgID load if not an overview account
    useEffect(() => {
        if (isOverviewAccount == false && orgID !== "" && orgID !== 'LZqI3R6MInUuwtgtROPK') {
        const unsubscribe = fetchOutlets(orgID, timezone, setOutlets, setOutletsLoading, setProdTypes);
        return () => unsubscribe();
        };
    }, []);

    // gets information upon orgID load if not an overview account
    useEffect(() => {
        if (selectedOrgID !== "") {
        const unsubscribe = fetchOutlets(selectedOrgID, timezone, setOutlets, setOutletsLoading, setProdTypes);
        return () => unsubscribe();
        };
    }, [selectedOrgID, timezone]);

    return (
        <>
        <div style={{
            minHeight:'100vh',
            backgroundColor:'#F2F4F8'
        }}>
            {user &&
            <>
                <Header
                    selectedOrgID={selectedOrgID}
                    setSelectedOrgID={setSelectedOrgID}
                    setTimezone={setTimezone}
                    setRate={setRate}
                    isOverviewAccount={isOverviewAccount}
                />
                <div style={{
                    marginTop:'65px',
                }}>
                    <ScheduleTable 
                    selectedOrgID={selectedOrgID}
                    outlets={outlets}></ScheduleTable>
                </div>
            </>
            }
        </div>
        </>
    )
}