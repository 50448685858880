import React, { useState, useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "./store/index.ts";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "./pages/login";
import RegisterScreen from "./pages/register/index.js";
import TestLogin from "./pages/login/testLogin";
import HomeScreen from "./pages/home";
import ScheduleScreen from "./pages/schedule";
import OutletsScreen from "./pages/admin-outlets/index.js";
import InventoryScreen from "./pages/inventory/index.js";
import UsersScreen from "./pages/users/index.js";
import PendingRoleScreen from "./pages/pending-role/index.js";
import "./assets/fonts/Manrope-VariableFont_wght.ttf"
import '@fontsource/roboto';
import OrganizationsScreen from "./pages/adminOrganizations/index.js";
import OrganizationRegisterScreen from "./pages/registerOrganization/index.js";
import AssignOutletsScreen from "./pages/assignOutlets/index.js";
import OrganizationDetails from "./pages/organizationDetails/index.js";
import AppliancesScreen from "./pages/appliances/index.js";
import OTPScreen from "./pages/otpRegister/index.js";
import OTPLoginScreen from "./pages/otpLogin/index.js";
//import "./assets/fonts/Poppins-Medium.ttf"

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          {/* <Route path='/testLogin' element={<TestLogin />} /> */}
          <Route path='/register' element={<RegisterScreen />} /> 
          <Route path="/" element={<HomeScreen />} />
          <Route path="/appliances" element={<AppliancesScreen />} />
          <Route path="/schedules" element={<ScheduleScreen />} />
          <Route path="/outlets" element={<OutletsScreen />} />
          <Route path="/inventory" element={<InventoryScreen />} />
          <Route path="/users" element={<UsersScreen/>}/>
          <Route path="/pending-role" element={<PendingRoleScreen/>}/>
          <Route path="/organizations" element={<OrganizationsScreen/>}/>
          <Route path="/organizations/register" element={<OrganizationRegisterScreen/>}/>
          <Route path="/organizations/register/outlets" element={<AssignOutletsScreen/>}/>
          <Route path="/profile" element={<OrganizationDetails />} />
          <Route path="/organizations/profile" element={<OrganizationDetails />} />
          <Route path="/otp" element={<OTPScreen />} />
          <Route path="/login/otp" element={<OTPLoginScreen />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
