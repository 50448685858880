import React, { useEffect, useState } from "react";
import { View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Typography, Button, Snackbar, Alert, Box } from "@mui/material";
import Header from "../../components/header";
import DataGridTable from "../../components/data-grid";
import { fetchAllOutletsInfo, fetchAllOrgInfo, fetchAllOutletsSnapshot, deleteOutletFromFirebase } from "../../functions/allOutletsInfo";
import { useLocation } from "react-router-dom";
import { green, greyDark, greyLight, yellow } from "../../lib/colors";
import RefreshIcon from '@mui/icons-material/Refresh';

export default function OutletsScreen() {
  const [user, waiting, error] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [timezoneConversion, setTimezoneConversion] = useState(0);
  const [outlets, setOutlets] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();
  const location = useLocation();
  const orgID = localStorage.getItem("orgID");

  useEffect(() => {
    if (waiting) return;
    if (!user) navigate("/login");
  }, [user, waiting, navigate]);

  useEffect(() => {
    fetchAllOrgInfo(setOrgData);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (orgData?.length !== 0) {
      const unsubscribe = fetchAllOutletsSnapshot(orgData, setOutlets);
      setLoading(false);
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [orgData]);

  const handleUpdateOutlets = async () => {
    setLoading(true);
    try {
      await fetchAllOutletsInfo(orgData, setOutlets);
      setSnackbarMessage("Outlets information updated successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage("Failed to update outlets information");
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const handleDeleteOutlet = async (selectedOutlet) => {
    setLoading(true);
    try {
      await deleteOutletFromFirebase(selectedOutlet);
    } catch (error) {
      console.error("Error deleting outlet: ", error);
    }finally {
      setLoading(false);
    }
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      {orgID === "LZqI3R6MInUuwtgtROPK" && (
        <View
          style={{
            backgroundColor: '#F2F4F8',
            minHeight: '100vh'
          }}
        >
          <Header orgID={orgID} />
          <Typography
            variant="h5"
            component="div"
            sx={{ alignSelf: "center", marginTop: '100px', marginBottom: '20px' }}
          >
            Admin Outlets Table
          </Typography>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={handleUpdateOutlets}
              disabled={loading}
            >
              Update
            </Button>
          </Box>
          <div style={{ marginTop: "1%" }}>
            <DataGridTable data={outlets} loading={loading} handleDeleteOutlet={handleDeleteOutlet} />
          </div>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </View>
      )}
    </>
  );
}