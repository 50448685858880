import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import UsageDashboardContainer from "./usageDashboardContainer";
import Header from "../../components/header";
import {
  fetchOrgID,
  fetchIsOverviewAccount,
} from "../../functions/organization";
import { fetchOutlets } from "../../functions/outletList";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";
import OrganizationPicker from "../../components/orgPicker";
import { green, greyDark, greyLight, greyUltraLight } from "../../lib/colors";
import { fetchUserRole } from "../../functions/users";
import {
  Box,
  CircularProgress,
} from "@mui/material";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function HomeScreen() {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [user, waiting, error] = useAuthState(auth);
  const [orgID, setOrgID] = useState("");
  const [rate, setRate] = useState(localStorage.getItem("rate") || 0.16);
  const [isOverviewAccount, setIsOverviewAccount] = useState(localStorage.getItem("isOverviewAccount") === "true" || false);
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC");
  const [roleLoading, setRoleLoading] = useState(true);
  const [outletsLoading, setOutletsLoading] = useState(true);
  const [wattsLoading, setWattsLoading] = useState(true);
  const [prodTypes, setProdTypes] = useState([]);
  const [selectedOrgID, setSelectedOrgID] = useState(localStorage.getItem("selectedOrgID") || "");
  const [outlets, setOutlets] = useState(JSON.parse(localStorage.getItem("outlets")));
  const [wattHours, setWattHours] = useState([]);
  const [outletAverageUsage, setOutletAverageUsage] = useState(
    JSON.parse(localStorage.getItem("outletAverageUsage"))
  );

  // gets userRole
  useEffect(() => {
    if (waiting) {
      // maybe trigger a loading screen
      return;
    }
    const getRole = async () => {
      const userRole = await fetchUserRole(user.uid);
      setRoleLoading(false);
      if (userRole == "pending") {
        navigate("/pending-role");
      }
    };
    if (user) {
      getRole();
    } else {
      navigate("/login");
    }
  }, [user, waiting]);

  // gets OrgID
  useEffect(() => {
    // // Function to fetch data from Firestore
    const fetchAndSetOrgID = async () => {
      if (orgID == "" || orgID == 'LZqI3R6MInUuwtgtROPK') {
        const {
          orgID: fetchedOrgID,
          timezone: fetchedTimezone,
          rate: fetchedRate,
        } = await fetchOrgID();
        if (fetchedOrgID) {
          setOrgID(fetchedOrgID);
          localStorage.setItem("orgID", fetchedOrgID);
          fetchIsOverviewAccount(fetchedOrgID, setIsOverviewAccount)
        }
        if (fetchedTimezone) {
          setTimezone(fetchedTimezone);
          localStorage.setItem("timezone", fetchedTimezone);
        }
        if (fetchedRate) {
          setRate(fetchedRate);
          localStorage.setItem("rate", fetchedRate);
        }
      }
    };
    fetchAndSetOrgID();
  }, []);

  // gets information upon orgID load if not an overview account
  useEffect(() => {
    if (isOverviewAccount == false && orgID !== "" && orgID !== 'LZqI3R6MInUuwtgtROPK') {
      setWattsLoading(true);
      setOutletsLoading(true);
      const unsubscribe = fetchOutlets(orgID, timezone, setOutlets, setOutletsLoading, setProdTypes);
      fetchWattHours(orgID, setWattHours, setWattsLoading);
      fetchAverageUsage(orgID, setOutletAverageUsage);
      setOutletsLoading(false);
      return () => unsubscribe();
    };
  }, [isOverviewAccount, orgID]);

  // gets information upon orgID load if not an overview account
  useEffect(() => {
    if (selectedOrgID !== "") {
      setWattsLoading(true);
      setOutletsLoading(true);
      const unsubscribe = fetchOutlets(selectedOrgID, timezone, setOutlets, setOutletsLoading, setProdTypes);
      fetchWattHours(selectedOrgID, setWattHours, setWattsLoading);
      fetchAverageUsage(selectedOrgID, setOutletAverageUsage);
      setOutletsLoading(false);
      return () => unsubscribe();
    };
  }, [selectedOrgID, timezone]);


  // This is for filtering
  const [selectedApplianceType, setSelectedApplianceType] = useState("");
  const handleApplianceTypeChange = (event) => {
    const selectedApplianceType = event.target.value;
    setSelectedApplianceType(selectedApplianceType);
    console.log("Fetching...");
    if (selectedOrgID !== "") {
      fetchWattHours(
        selectedOrgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
      fetchAverageUsage(
        selectedOrgID,
        setOutletAverageUsage,
        null,
        selectedApplianceType
      );
    } else {
      fetchWattHours(
        orgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
      fetchAverageUsage(
        orgID,
        setOutletAverageUsage,
        null,
        selectedApplianceType
      );
    }
    console.log("Fetched!!");
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //localStorage.clear();
  //console.log(selectedOrgID);
  //console.log(outlets);

  return (
    <div>
      {orgID !== "" &&
        <Header
          selectedOrgID={selectedOrgID}
          setSelectedOrgID={setSelectedOrgID}
          setTimezone={setTimezone}
          setRate={setRate}
          width={windowDimensions.width}
          isOverviewAccount={isOverviewAccount}
        />
      }
      <div style={{
        position: 'absolute',
        top: 65,
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundColor: '#F2F4F8',
      }}>
        <div
          style={{
            width: '100vw',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {wattsLoading || outletsLoading ? (
            <CircularProgress />)
            :
            <div
              style={{
                marginTop: 10,
                width: windowDimensions.width < 1400 ? "92.5vw" : "1300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <UsageDashboardContainer
                orgID={selectedOrgID !== "" ? selectedOrgID : orgID}
                outlets={
                  selectedApplianceType &&
                    selectedApplianceType != "" &&
                    selectedApplianceType !== "All Outlets"
                    ? selectedApplianceType == "N/A"
                      ? outlets.filter(
                        (outlet) => outlet.productType === ""
                      )
                      : outlets.filter(
                        (outlet) =>
                          outlet.productType === selectedApplianceType
                      )
                    : outlets
                }
                wattHours={wattHours}
                outletAverageUsage={outletAverageUsage}
                height={windowDimensions.height}
                width={windowDimensions.width}
                selectedApplianceType={selectedApplianceType}
                handleApplianceTypeChange={handleApplianceTypeChange}
                prodTypes={prodTypes}
                rate={rate}
              />
            </div>
          }
        </div>
      </div>
    </div>

  );
}
