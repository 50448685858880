import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../../firebase";
import {
  green,
  greyDark,
  greyLight,
  greyMedium,
  greyUltraDark,
  white,
  blue,
  blueDark,
} from "../../../lib/colors";
import { scale, verticalScale } from "../../../lib/screen-sizes";
import { sendPasswordResetEmail, signOut } from "@firebase/auth";
import logo from "../../../assets/images/logo.png";
import Modal from "../../../components/modal";
import {
  Box,
  Link,
  TextField,
  Alert,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GlobalSavings from "../../../components/global-savings";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function Login({ onLogin, open, handleClose }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, waiting, error] = useAuthState(auth);
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "",
  });

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (user) {
      if (location.state?.from === "/organizations/register") {
        console.log("signing out");
        signOut(auth);
      }
      navigate("/");
    }
  }, [user]);

  // const newUser = () => navigate('/register');
  const sendResetEmail = async () => {
    if (email !== "") {
      try {
        await sendPasswordResetEmail(auth, email);
        setAlert({
          show: true,
          message: "Please check your inbox to reset your password",
          severity: "info",
        });
      } catch (error) {
        if (error.message === "Firebase: Error (auth/invalid-email).") {
          setAlert({
            show: true,
            message: "Please verify that you entered the correct email",
            severity: "error",
          });
        }
      }
    } else {
      setAlert({
        show: true,
        message: "Please enter your email",
        severity: "error",
      });
    }
  };

  const registerNewOrganization = () => {
    // Pass the current location (or page) as state
    navigate("/organizations/register", {
      state: { from: window.location.pathname },
    });
  };

  const registerNewUser = () => {
    navigate("/register");
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div style={{
        display: "flex",
        flexDirection: "row",
      }}>
        <div
          style={{
            display: "flex",
            height: windowDimensions.height,
            width: '70%',
            minWidth: '500px',
            backgroundColor: greyLight,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: "100%",
          }}
        >
          <div
            style={{
              width: "60%",
              minWidth:'500px',
              borderRadius: 25,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: white,
              marginTop: 100,
            }}
          >
            <img
              src={logo}
              alt="Revert Logo"
              style={{
                width: "400px",
                height: "auto",
                display: "block",
                marginTop: "2.5%",
                marginBottom: "10%",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: 'end',
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: "-5%",
                // marginBottom: "1%",
              }}
            >
              <div
                style={{
                  marginLeft: "2.5%",
                }}
              >
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                  margin="normal"
                  required
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "250",
                    color: "greyUltraDark",
                    alignSelf: "center",
                    width: "97.5%",
                  }}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Manrope",
                      fontWeight: 250,
                      color: "#A9A9A9",
                    },
                  }}
                />
                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  margin="normal"
                  required
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "250",
                    alignSelf: "center",
                    width: "97.5%",
                  }}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Manrope",
                      fontWeight: 250,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onLogin(email, password, "email");
                    }
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "1%",
                  marginLeft: "-45%",
                }}
              ></div>
              <Box
                sx={{ cursor: "pointer", marginLeft: "5%", marginTop: "5px" }}
                onClick={sendResetEmail}
              >
                <Link
                  underline="hover"
                  sx={{
                    color: greyDark,
                    fontFamily: "Manrope",
                    fontWeight: "250",
                  }}
                >
                  Forgot password?
                </Link>
              </Box>
              {alert.show && (
                <Alert
                  severity={alert.severity}
                  sx={{ mt: 2, fontFamily: "Manrope" }}
                >
                  {alert.message}
                </Alert>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "1%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2%",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    borderRadius: 15,
                    backgroundColor: blue,
                    overflow: "hidden",
                    textAlign: "center",
                    marginTop: "10%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onLogin(email, password, "email");
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      fontFamily: "Manrope",
                      alignSelf: "center",
                      padding: "5%",
                    }}
                  >
                    Login
                  </p>
                </div>
              </div>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                {/* <div
                  style={{
                    width: "250px",
                    borderRadius: 15,
                    backgroundColor: greyMedium,
                    overflow: "hidden",
                    textAlign: "center",
                    marginTop: "5%",
                  }}
                  onClick={registerNewUser}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      fontFamily: "Manrope",
                      alignSelf: "center",
                      padding: "5%",
                      cursor: "pointer",
                    }}
                  >
                    New user?
                  </p>
                </div> */}
                <div
                  style={{
                    width: "250px",
                    borderRadius: 15,
                    backgroundColor: greyDark,
                    overflow: "hidden",
                    textAlign: "center",
                    marginTop: "5%",
                  }}
                  onClick={registerNewOrganization}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      fontFamily: "Manrope",
                      alignSelf: "center",
                      padding: "5%",
                      cursor: "pointer",
                    }}
                  >
                    New organization?
                  </p>
                </div>
              </Box>
            </div>
            <div
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontFamily: "Manrope",
                color: greyUltraDark,
                fontSize:
                  windowDimensions.width < 1300
                    ? "14px"
                    : windowDimensions.width / 95,
              }}
            >
              <p>
                By clicking "Login" you agree to Revert's{" "}
                <a
                  href="https://revert-tech.com/policies/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://revert-tech.com/policies/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            height: windowDimensions.height,
            minWidth: '450px',
            backgroundColor: white,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: "100%",
          }}
        >
          <GlobalSavings />
        </div>
      </div>
      {open === true && (
        <Modal isOpen={open} onClose={handleClose}>
          <div
            style={{
              border: `15px solid ${greyMedium}`,
              borderRadius: 45,
              width: "500px",
              height: "150px",
              textAlign: "center",
            }}
          >
            <p
              style={{
                marginTop: "10%",
                fontFamily: "Manrope",
                fontSize: "28px",
              }}
            >
              Incorrect Login Information
            </p>
            <p
              style={{
                marginTop: "1%",
                fontFamily: "Manrope",
                fontSize: "20px",
              }}
            >
              Click anywhere to continue
            </p>
          </div>
        </Modal>
      )}
    </>
  );
}
