import React, { useEffect, useState } from "react";
import { View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import Header from "../../components/header";
import { fetchOrgID } from "../../functions/outletList";
import db from "../../firebase";
import InventoryDataGridTable from "../../components/inventory-data-grid";
import {
  cleanNewDevices,
  fetchAllOrganizations,
  fetchNewDevices,
} from "../../functions/inventory";
import {useLocation} from 'react-router-dom';

export default function InventoryScreen() {
  const [user, waiting, error] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [timezoneConversion, setTimezoneConversion] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const orgID = localStorage.getItem("orgID");

  useEffect(() => {
    if (waiting) {
      return;
    }
    // console.log(user.email);
    if (!user || !orgID) navigate("/login");
  }, [user, waiting]);
  const [newDevices, setNewDevices] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  //   This is for cleaning the collection newDevices
  //   useEffect(() => {
  //     cleanNewDevices();
  //   }, []);
  const getNewDevices = async () => {
    setLoading(true);
    const devicesData = await fetchNewDevices();
    //   console.log("Fetched new devices data: ", devicesData);
    setNewDevices(devicesData);
    setLoading(false);
  };
  const getOrganizations = async () => {
    setLoading(true);
    const organizationsData = await fetchAllOrganizations();
    //   console.log("Fetched organizations data: ", organizationsData);
    setOrganizations(organizationsData);
    setLoading(false);
  };

  useEffect(() => {
    getNewDevices();
    getOrganizations();
  }, []);
  return (
    <>
      {orgID === "LZqI3R6MInUuwtgtROPK" && (
        <View
          style={{
            backgroundColor:'#F2F4F8',
            minHeight:'100vh'
          }}
        >
          <Header orgID={orgID}/>
          <div style={{marginTop:'100px'}}>
          <InventoryDataGridTable
            data={newDevices}
            loading={loading}
            organizations={organizations}
            getNewDevices={getNewDevices}
          ></InventoryDataGridTable>
          </div>
        </View>
      )}
    </>
  );
}
