import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import logo from "../../assets/images/logo.png";
import { auth } from "../../firebase";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { registerNewUser } from "../../functions/users";
import { registerOrganization } from "../../functions/organization";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

export default function OrganizationRegistration() {
  const [organizationName, setOrganizationName] = useState("");
  const [utilityRate, setUtilityRate] = useState("0.16");
  const [rateError, setRateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [processing, setProcessing] = useState(false); // State to manage loading overlay

  const [zipCode, setZipCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const [user, waiting] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object to determine the referring page

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewEmail("");
    setErrorMessage("");
  };

  const handleConfirmAddEmail = async () => {
    try {
      const auth = getAuth();
      const signInMethods = await fetchSignInMethodsForEmail(auth, newEmail);

      if (signInMethods.length <= 0) {
        setEmails([...emails, newEmail]);
        handleCloseDialog();
      } else {
        setErrorMessage("This email is already registered for another user.");
      }
    } catch (error) {
      setErrorMessage("Invalid email");
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };

  const handleRateChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setUtilityRate(value);
      setRateError(false);
    } else {
      setRateError(true);
    }
  };

  const handleZipcodeChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,5}$/.test(value)) {
      setZipCode(value);
      setZipcodeError(false);
    } else {
      setZipcodeError(true);
    }
  };

  const handleUsersCreation = async () => {
    const auth = getAuth();
    let users = [];

    // Determine the role based on the referring page
    const role = location.state?.from === "/login" ? "pending" : "admin";

    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];
      let orgName = organizationName.split(" ");
      const password = `${orgName[0]}${i}!`;

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        await registerNewUser(user, null, role); // Pass the determined role ("pending" or "admin")
        users.push(user.uid);
      } catch (error) {
        console.error(`Error creating user with email ${email}:`, error);
        setAlert({
          show: true,
          message: `Error creating user with email ${email}: ${error.message}`,
          severity: "error",
        });
        return false;
      }
    }

    return users;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate before submission
    if (rateError) {
      setAlert({
        show: true,
        message: "Please enter a valid utility rate.",
        severity: "error",
      });
      return;
    }
    if (organizationName.length == 0) {
      setAlert({
        show: true,
        message: "Please enter a valid organization name.",
        severity: "error",
      });
      return;
    }
    if (zipCode.length < 5) {
      setAlert({
        show: true,
        message: "Please enter a valid zip code.",
        severity: "error",
      });
      return;
    }

    setProcessing(true); // Start processing (show overlay)

    try {
      let users = await handleUsersCreation();
      if (users) {
        // Combine the address fields into one address string
        const address = `${streetAddress}, ${city}, ${state} ${zipCode}`;

        // Determine the organization status based on the referring page
        const orgStatus =
          location.state?.from === "/login" ? "pending" : "active";

        const organization = {
          joinDate: "",
          name: organizationName,
          rate: parseFloat(utilityRate) || 0.16,
          uid: "",
          users: users,
          address: address,
          zipcode: zipCode,
          status: orgStatus,
        };

        const registeredID = await registerOrganization(organization);

        // Show success alert
        setAlert({
          show: true,
          message: "Organization registered successfully!",
          severity: "success",
        });
        setTimeout(() => {
          location.state?.from === "/login"
            ? navigate("/login", {
                //state: { from: window.location.pathname },
              })
            : navigate("/organizations/register/outlets", {
                state: { registeredID: registeredID },
              });
        }, 1500);
      }
    } catch (error) {
      console.error("Error registering organization:", error);
      setAlert({
        show: true,
        message: `Error registering organization: ${error.message}`,
        severity: "error",
      });
    } finally {
      setProcessing(false); // End processing
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Box
        sx={{
          maxWidth: "500px",
          width: "100%",
          padding: "20px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <img
            src={logo}
            alt="ReVert Logo"
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "auto",
            }}
          />
        </Box>
        <Typography
          variant="h4"
          component="h1"
          sx={{ textAlign: "center", mb: 3 }}
        >
          Register Organization
        </Typography>
        {alert.show && (
          <Alert severity={alert.severity} sx={{ mb: 3 }}>
            {alert.message}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Organization Name"
            variant="outlined"
            fullWidth
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Utility Rate"
            defaultValue={0.16}
            variant="outlined"
            fullWidth
            onChange={handleRateChange}
            error={rateError}
            helperText={rateError ? "Please enter a valid number" : ""}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$/kWh</InputAdornment>
              ),
            }}
          />
          <TextField
            label="Street Address"
            variant="outlined"
            fullWidth
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="City"
            variant="outlined"
            fullWidth
            value={city}
            onChange={(e) => setCity(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="State"
            variant="outlined"
            fullWidth
            value={state}
            onChange={(e) => setState(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Zip Code"
            variant="outlined"
            fullWidth
            value={zipCode}
            onChange={handleZipcodeChange}
            sx={{ mb: 2 }}
            inputProps={{
              maxLength: 5,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            error={zipcodeError}
            helperText={zipcodeError ? "Please enter a valid number" : ""}
          />
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            User Emails
          </Typography>
          {emails.map((email, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <TextField
                label={`Email ${index + 1}`}
                variant="outlined"
                fullWidth
                value={email}
                disabled
                sx={{ mr: 2 }}
              />
              <IconButton onClick={() => handleRemoveEmail(index)}>
                <RemoveIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={handleOpenDialog}
            sx={{ mb: 3 }}
          >
            Add Email
          </Button>

          {location.state?.from === "/login" && (
            <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
              Users added will become admins once the organization has
              been approved. Additional users and roles can be added later.
            </Typography>
          )}

          <Button type="submit" variant="contained" color="primary" fullWidth>
            Register Organization
          </Button>
        </form>
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="confirm-email-dialog"
          aria-describedby="confirm-email-description"
        >
          <DialogTitle id="confirm-email-dialog">Confirm Email</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-email-description">
              Please confirm the email to add:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button
              onClick={handleConfirmAddEmail}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {processing && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          open={processing}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  );
}
