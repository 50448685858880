import React, { useState, useEffect } from "react";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import {
  green,
  greyDark,
  greyLight,
  yellow,
  orange,
} from "../../../../lib/colors";

export default function OutletDataContainer({ width, outlet }) {
  const getWifiStatusIcon = () => {
    switch (outlet.wifiStatus) {
      case "Connected":
        return <WifiIcon style={{ color: green, fontSize: width < 1450 ? "1.25vw" : "30px" }} />;
      case "Disconnected":
        return <WifiOffIcon style={{ color: greyDark, fontSize: width < 1450 ? "1.25vw" : "30px" }} />;
      default:
        return <WifiLockIcon style={{ color: orange, fontSize: width < 1450 ? "1.25vw" : "30px" }} />;
    }
  };

  const formatLastDataUpdate = () => {
    let lastDataUpdate = "No Data";
    if (outlet.lastDataUpdate) {
      lastDataUpdate = outlet.lastDataUpdate;
      const [dateString, timeString] = lastDataUpdate.split(" ");
      //   Date format
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${month}-${day}-${year}`;
      //   Time Format
      const [hours, minutes, seconds] = timeString.split(":");
      const formattedTime = `${hours}:${minutes}`;

      const formattedDateTime = `${formattedDate} ${formattedTime}`;
      return formattedDateTime;
    } else {
      return lastDataUpdate;
    }
  };

  const getStatus = () => {
    switch (outlet.status) {
      case "On":
        return (
          <LightbulbIcon
            style={{ color: green, fontSize: width < 1450 ? "1.25vw" : "30px" }}
          />
        );
      case "Off":
        return (
          <LightbulbIcon
            style={{
              color: greyDark,
              fontSize: width < 1450 ? "1.25vw" : "30px",
            }}
          />
        );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: 'white',//greyLight,
        borderRadius: "15px",
        width: "99%",
        height: width*0.06, //windowDimensions.width < 1100 ? '6vw' : '95px',
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          width: "100%",
          alignItems: "center",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: width*0.105,
          }}
        >
          <p
            style={{
              fontSize: width < 1450 ? width*0.0125 : "20px",
              textDecorationLine: "underline",
              fontFamily: "manrope",
              marginBottom: "7.5%",
            }}
          >
            On/Off
          </p>
          <p
            style={{
              fontSize: width < 1450 ? width*0.0125 : "20px",
              fontFamily: "manrope",
            }}
          >
            {getStatus()}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "10.5vw",
          }}
        >
          <p
            style={{
              fontSize: width < 1450 ? width*0.0125 : "20px",
              textDecorationLine: "underline",
              fontFamily: "manrope",
              marginBottom: "7.5%",
            }}
          >
            WiFi Status
          </p>
          <p
            style={{
              fontSize: width < 1450 ? width*0.0125 : "20px",
              fontFamily: "manrope",
            }}
          >
            {getWifiStatusIcon()}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: width*0.15,
          }}
        >
          <p
            style={{
              fontSize: width < 1450 ? width*0.0125 : "20px",
              textDecorationLine: "underline",
              fontFamily: "manrope",
              marginBottom: "10%",
            }}
          >
            Last Data Received
          </p>
          <p
            style={{
              fontSize: width < 1450 ? width*0.0125 : "20px",
              fontFamily: "manrope",
            }}
          >
            {formatLastDataUpdate()}
          </p>
        </div>
      </div>
    </div>
  );
}
