import React from "react";
import { green, white, blue, greyMedium } from "../../../../../lib/colors";
import co2Image from '../../../../../assets/images/co2Dark.png';
import energyImage from '../../../../../assets/images/energy.png';
import moneyImage from '../../../../../assets/images/money.png';

export default function LifetimeSavingsPresentational({
    organizationSavings,
    width,
    height,
    monthly,
}) {
    let totalCO2eSavings = 0.0;
    let totalCostSavings = 0.0;
    let totalEnergySavings = 0.0;

    // if(organizationSavings.length == 0){
    //     organizationSavings[0].co2eSavings = 0;
    //     organizationSavings[1].costSavings = 0;
    //     organizationSavings[2].energySavings = 0;
    // }

    if (monthly === true) {
        organizationSavings[0].co2eSavings.forEach(outlet => {
            if (isNaN(Object.values(outlet)[0])) {
                totalCO2eSavings += 0;
            } else {
                totalCO2eSavings += Object.values(outlet)[0] || 0;
            };
        });
        organizationSavings[1].costSavings.forEach(outlet => {
            if (isNaN(Object.values(outlet)[0])) {
                totalCostSavings += 0;
            } else {
                totalCostSavings += Object.values(outlet)[0] || 0;
            };
        });
        organizationSavings[2].energySavings.forEach(outlet => {
            if (isNaN(Object.values(outlet)[0])) {
                totalEnergySavings += 0;
            } else {
                totalEnergySavings += Object.values(outlet)[0] || 0;
            };
        });
    }else if(organizationSavings[2].energySavings){
        totalCO2eSavings = organizationSavings[0].co2eSavings
        totalCostSavings = organizationSavings[1].costSavings
        totalEnergySavings = organizationSavings[2].energySavings
    }

    const savingsStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: green,
        borderRadius: '10px',
        marginTop: '1%',
        width: '30%',
        marginLeft: '1%',
        marginRight: '1%',
    };
    const textLabelStyle = {
        marginTop: '1%',
        fontSize: width < 1400 ? width*0.015 : '20px',
        textDecorationLine: 'underline',
        textAlign: 'center',
        color: white,
        fontFamily: 'manrope',
    };
    const textValueStyle = {
        fontSize: width < 1400 ? width*0.015  : '22.5px',
        //marginTop: '5%',
        textAlign: 'center',
        fontFamily: 'manrope',
        color: white,
        marginBottom: '2%',
    };
    
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            width:'98%',
            marginLeft:'1%',
            alignSelf:'center',
            //height: width < 1400 ? width*0.1: '75%',
            marginBottom: '2%',
        }}>
            <div style={savingsStyle}>
                <img
                    src={energyImage}
                    alt="lightning bolt with arrows circling it"
                    style={{
                        width: 'auto',
                        height: width*0.03,
                        marginTop: '2%',
                    }}
                />
                <p style={textValueStyle}>
                    {(totalEnergySavings / 1000).toFixed(2)}<br/>kWh
                </p>
            </div>
            <div style={savingsStyle}>
                <img
                    src={co2Image}
                    alt="CO2 in the cloud"
                    style={{
                        width: 'auto',
                        height: width*0.03,
                        marginTop: '2%',
                        //marginBottom: '10%',
                    }}
                />
                <p style={textValueStyle}>
                    {totalCO2eSavings.toFixed(2)}<br/>lbs CO2e
                </p>
            </div>
            <div style={savingsStyle}>
                <img
                    src={moneyImage}
                    alt="hand with a dollar sign above it"
                    style={{
                        width: 'auto',
                        height: width*0.03,
                        marginTop: '2%',
                        //marginBottom: '10%',
                    }}
                />
                <p style={textValueStyle}>
                    ${totalCostSavings.toFixed(2)}
                </p>
            </div>
        </div>
    );
};