import React from "react";
import { buildDay } from "../../../../lib/date-utils";
import TopEnergyDrainerPresentational from "./topEnergyDrainerPresentational";
import { filterByDate } from "../../../../functions/wattHours";
import { LineChart } from "../../../../components/newGraphLibrary/graphs";
import { green, greyLight } from "../../../../lib/colors";
import chartImage from '../../../../assets/images/pieChart.png';
import { GraphHeader } from "../../../../components/graphHeader";

export default function HourlyUsageGraphPresentational({
    orgID,
    timezone,
    outlets,
    outletAverageUsage,
    wattHours,
    width,
    height,
}) {
    const dailyData = buildDay();
    const averageDailyData = buildDay();

    let dailyTotal = 0;
    let dailyHigh = 0;
    let dailyLow;

    const usagePresentationalStyle = {
        backgroundColor: 'white',//greyLight,
        marginTop: '1%',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
    };

    let avgSum = 0
    let sum = 0
    for (let datum of dailyData) {
        sum += datum.y;
    };

    //correct for the users timezone
    let time = new Date().toLocaleDateString('en-US', {
        timeZone: timezone,
      });
    // Split the date string into components
    const [monthTZ, dayTZ, yearTZ] = time.split('/');
    const today = new Date();
    today.setDate(dayTZ)
    today.setMonth(monthTZ-1)
    today.setFullYear(yearTZ)
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayFormatted = `${year}-${month}-${day}`;
    const singleDayWattHours = filterByDate(wattHours, todayFormatted);
    const dayName = today.toLocaleDateString('en-US', { weekday: 'short' });

    //get all the online outlets
    const connected_outlets = outlets.filter((outlet) => outlet.wifiStatus === "Connected")
    const connected_outlet_arr = []
    connected_outlets.map((outlet) => {
        connected_outlet_arr.push(outlet.id)
    })

    //only get the average usage line for online outlets
    for (const outlet in outletAverageUsage) {
        if(connected_outlet_arr.includes(outlet)){
            for (let i = 0; i < 24; i++) {
                if (outletAverageUsage[outlet][dayName] !== undefined) {
                    if (outletAverageUsage[outlet][dayName].length !== 0) {
                        averageDailyData[i].y += outletAverageUsage[outlet][dayName][i];
                    }
                };
                if(!averageDailyData[i].y){
                    averageDailyData[i].y = 0
                }
            };
        }
    };

    let dataBool = false;
    //console.log(singleDayWattHours)
    const outletUsageObject = {};
    singleDayWattHours.forEach((outlet, index) => {
        outletUsageObject[outlet.outletID] = 0
        outlet.hourlyWattHour.forEach((hour, index) => {
            if (hour !== -1) {
                dataBool = true
                dailyData[index].y += hour;
                dailyTotal+=hour;
                outletUsageObject[outlet.outletID] += hour;
            };
        });
    });

    const data = [{ id: 'series1', color: green, data: dailyData },]
    const averageData = { id: 'series2', color: 'red', data: averageDailyData }

    let maxKey = null;
    let maxValue = -Infinity;
    const topUsageObject = {};
    for (const key in outletUsageObject) {
        if (outletUsageObject.hasOwnProperty(key)) {
            if (outletUsageObject[key] > maxValue) {
                maxValue = outletUsageObject[key];
                maxKey = key;
            }
        }
    }
    topUsageObject[maxKey] = maxValue;

    if (dataBool == true) {
        return (
            <div style={usagePresentationalStyle}>
                <div style={{ width: '100%', height:'85%',}}>
                    <p style={{
                        fontWeight: '600',
                        fontSize: width < 1000 ? width*0.02 : '20px',
                        fontFamily: 'manrope',
                        marginTop: '2%',
                        marginLeft: '3%',
                    }}>
                        Hourly Usage
                    </p>
                    <div style={{
                        display:'flex',
                        flexDirection: 'column',
                        width: '98%',
                        marginLeft: '1%',
                        alignItems: 'center',
                        marginTop: '-2%',
                    }}>
                        <GraphHeader type={'hourly'} width={width}/>
                        <div style={{
                            height: width < 1400 ? width*0.185 : '265px',
                            //marginTop: '-0.5%',
                            width: '98%',
                        }}>
                            <LineChart data={data} averageData={averageData} width={width} />
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', height:'15%'}}>
                    <TopEnergyDrainerPresentational
                        dailyTotal={dailyTotal}
                        topUsageObject={topUsageObject}
                        outlets={outlets}
                        height={height}
                        width={width}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div style={usagePresentationalStyle}>
                <div style={{
                    display: 'flex',
                    flexDirection:'column',
                    //alignItems: 'center',
                    //justifyContent: 'center',
                    height:'100%',
                    width: '100%',
                }}>
                    <p style={{
                        fontWeight: '600',
                        fontSize: width < 1000 ? width*0.02 : '20px',
                        fontFamily: 'manrope',
                        marginTop: '2%',
                        marginLeft: '3%',
                    }}>
                        Hourly Usage
                    </p>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '75%',
                    }}>
                        <img
                            src={chartImage}
                            alt="pie chart"
                            style={{
                                width: 'auto',
                                height: width < 1050 ? width*0.145 : '150px',
                            }}
                        />
                        <p
                            style={{
                                fontSize: width < 1050 ? width*0.02 : '27px',
                                fontFamily: 'Manrope',
                                fontWeight: '600',
                                marginTop: '2%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            No Chart Data
                        </p>
                        <p
                            style={{
                                textAlign: 'center',
                                fontFamily: 'Manrope',
                                marginTop: '1%',
                                fontSize: width < 1050 ? width*0.02 : '22px',
                            }}>
                            Check back later to see hourly usage.
                        </p>
                    </div>
                </div>
            </div>
        );
    };
};