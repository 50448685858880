import React, { useEffect, useState } from "react";
import OrganizationModal from "../../components/organizationModal";
import Header from "../../components/header";
import { Box, Typography, Button, } from "@mui/material";
import UsersDataGridTable from "../../components/users-data-grid";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../../firebase";
import { fetchUsersFromOrganization } from "../../functions/users";
import { logout  } from "../../firebase";
import { green, greyDark } from "../../lib/colors";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const OrganizationDetails = () => {
  const [user, waiting] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const orgID = localStorage.getItem("orgID");
  const [isOverviewAccount, setIsOverviewAccount] = useState(localStorage.getItem("isOverviewAccount") === "true" || false);
  const [selectedOrgID, setSelectedOrgID] = useState(localStorage.getItem("selectedOrgID") || "");
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC"
  );
  const [rate, setRate] = useState(localStorage.getItem("rate") || 0.16);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (waiting) return;
    if (!user) navigate("/login");
  }, [user, waiting, navigate]);

  const getUsers = async () => {
    setLoading(true);
    let usersData;
    if(selectedOrgID !== ""){
      usersData = await fetchUsersFromOrganization(selectedOrgID);
    }
    else{
      usersData = await fetchUsersFromOrganization(orgID);
    }
    setUsers(usersData);
    setLoading(false);
  };

  useEffect(() => {
    if (orgID) {
      getUsers();
    };
  }, [orgID, selectedOrgID]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: '#F2F4F8',
      }}
    >
      <Header
        selectedOrgID={selectedOrgID}
        setSelectedOrgID={setSelectedOrgID}
        setTimezone={setTimezone}
        setRate={setRate}
        width={windowDimensions.width}
        isOverviewAccount={isOverviewAccount}
      />
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          mt: 4,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            p: 3,
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ mb: 2, fontWeight: "bold", marginTop: "50px" }}
            >
              Organization Details
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                if (user) {
                  logout();
                  window.location.reload();
                  localStorage.clear();
                }
              }}
              sx={{
                position: "absolute",
                marginLeft: "35%",
                mt: 4,
                minWidth: '100px',
                fontSize: { xs: "2.25vw", sm: "22px" },
                backgroundColor: user ? greyDark : green,
                color: "white",
                borderRadius: "50px",
                width: { xs: "15vw", sm: "150px" },
                height: { xs: "5vw", sm: "45px" },
                "&:hover": {
                  backgroundColor: user ? greyDark : green,
                },
              }}
            >
              Sign Out
            </Button>
          </Box>
          <OrganizationModal
            open={true}
            handleClose={null}
            org={selectedOrgID!== "" ? selectedOrgID : orgID}
            organizations={null}
            isModal={false}
            selectedOrgID={selectedOrgID}
          />
          <UsersDataGridTable
            data={users}
            loading={loading}
            getUsers={getUsers}
            orgID={selectedOrgID!== "" ? selectedOrgID : orgID}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationDetails;
