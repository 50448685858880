import {
  query,
  collection,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import db from "../firebase";

export const fetchWattHours = (
  orgID,
  setWattHours,
  setWattsLoading,
  outletID = null,
  applianceType = null
) => {
  let wattHours = [];
  const today = new Date();
  const dayOfWeek = today.getDay();
  const previousMonday = new Date(today);
  previousMonday.setDate(today.getDate() - ((dayOfWeek + 6) % 7));
  const fiveWeeksAgo = new Date(previousMonday);
  fiveWeeksAgo.setDate(previousMonday.getDate() - 35);
  const year = fiveWeeksAgo.getFullYear();
  const month = String(fiveWeeksAgo.getMonth() + 1).padStart(2, "0");
  const day = String(fiveWeeksAgo.getDate()).padStart(2, "0");
  const fiveWeeksAgoString = `${year}-${month}-${day}`;
  let wattHoursQ;
  //get new wattHours collection
  const getWattHours = async () => {
    if (outletID) {
      // ----------------> If there is an outletID, we need to filter
      wattHoursQ = query(
        collection(db, "hourlyWattHours"),
        where("orgID", "==", orgID),
        where("date", ">=", fiveWeeksAgoString),
        where("outletID", "==", outletID)
      );
    } else if (
      applianceType &&
      applianceType !== "" &&
      applianceType !== "All Outlets"
    ) {
      wattHoursQ = query(
        collection(db, "hourlyWattHours"),
        where("orgID", "==", orgID),
        where("date", ">=", fiveWeeksAgoString),
        where("productType", "==", applianceType === "N/A" ? "" : applianceType)
      );
    }
    // TODO: this query should pull based on orgID can add orgID pull section once the whole database is in the new collection
    else {
      //console.log("Not filtered")
      wattHoursQ = query(
        collection(db, "hourlyWattHours"),
        where("orgID", "==", orgID),
        where("date", ">=", fiveWeeksAgoString)
      );
    }
    const wattHoursQuerySnapshot = await getDocs(wattHoursQ);
    //console.log("WattHoursQS: ", wattHoursQuerySnapshot)

    wattHoursQuerySnapshot.forEach((doc) => {
      wattHours.push(doc.data());
    });
    const sortedWattHours = wattHours.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    setWattHours(sortedWattHours);
    setWattsLoading(false);
  };
  getWattHours();
};

export const filterByDate = (array, targetDate) => {
  return array.filter((item) => item.date === targetDate);
};

export const filterByDateRange = (array, startDate, endDate) => {
  // Convert startDate and endDate to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  return array.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= start && itemDate <= end;
  });
};

export const sumValues = (array) => {
  return array.reduce((accumulator, currentValue) => {
    // Check if currentValue is not -1
    if (currentValue !== -1) {
      return accumulator + currentValue;
    } else {
      return accumulator;
    }
  }, 0);
};

export const sumHoursByOutletID = (array) => {
  return array.reduce((accumulator, currentItem) => {
    const { outletID, hourlyWattHour } = currentItem;
    const onlineValues = hourlyWattHour.filter((num) => num >= 0);
    const hoursSum = sumValues(onlineValues);

    // If outletID already exists, add the sum of hours to the existing value
    if (accumulator[outletID]) {
      accumulator[outletID] += hoursSum;
    } else {
      // If outletID does not exist, initialize it with the sum of hours
      accumulator[outletID] = hoursSum;
    }

    return accumulator;
  }, {});
};

export const sumHoursByOutletName = (array) => {
  return array.reduce((accumulator, currentItem) => {
    const { name, hourlyWattHour } = currentItem;
    const onlineValues = hourlyWattHour.filter((num) => num >= 0);
    const hoursSum = sumValues(onlineValues);

    // If outletID already exists, add the sum of hours to the existing value
    if (accumulator[name]) {
      accumulator[name] += hoursSum;
    } else {
      // If outletID does not exist, initialize it with the sum of hours
      accumulator[name] = hoursSum;
    }

    return accumulator;
  }, {});
};

export const fetchAverageUsage = async (
  orgID,
  setOutletAverageUsage,
  outletID = null,
  applianceType = null
) => {
  let averageUsageObject = {};
  const getAverageUsage = async () => {
    let averageUsageQ;
    if (outletID) {
      averageUsageQ = query(
        collection(db, "averageUsage"),
        where("orgID", "==", orgID),
        where("outletID", "==", outletID)
      );
    } else if (
      applianceType &&
      applianceType !== "" &&
      applianceType !== "All Outlets"
    ) {
      averageUsageQ = query(
        collection(db, "averageUsage"),
        where("orgID", "==", orgID),
        where("productType", "==", applianceType === "N/A" ? "" : applianceType)
      );
    } else {
      averageUsageQ = query(
        collection(db, "averageUsage"),
        where("orgID", "==", orgID)
      );
    }
    const averageUsageSnapshot = await getDocs(averageUsageQ);
    averageUsageSnapshot.forEach((doc) => {
      // create an object that is outletID: [{average usage} for each day]
      const outletKey = doc.data().outletID;
      averageUsageObject[outletKey] = doc.data().average_usage_data;
    });
    localStorage.setItem(
      "outletAverageUsage",
      JSON.stringify(averageUsageObject)
    );
    setOutletAverageUsage(averageUsageObject);
  };
  getAverageUsage();
};

export const fetchAverageUsageData = async (
  orgID,
  setOutletAverageUsage,
  outletID = null
) => {
  let averageUsageData;

  const fetchAndSetAverageUsage = async (outletID) => {
    averageUsageData = await getDoc(
      doc(
        db,
        "organizations",
        orgID,
        "Outlets",
        outletID,
        "averageUsage",
        "weeklyAverageUsage"
      )
    );

    const averageOutletData = [];
    if (
      averageUsageData &&
      averageUsageData._document !== null &&
      Object.keys(
        averageUsageData._document.data.value.mapValue.fields.average_usage_data
          .mapValue
      ).length !== 0
    ) {
      const day_keys = Object.keys(
        averageUsageData._document.data.value.mapValue.fields.average_usage_data
          .mapValue.fields
      );
      for (let j = 0; j < day_keys.length; j++) {
        if (
          averageUsageData._document.data.value.mapValue.fields
            .average_usage_data.mapValue.fields[day_keys[j]].arrayValue
            .values != null
        ) {
          averageOutletData.push(
            averageUsageData._document.data.value.mapValue.fields
              .average_usage_data.mapValue.fields[day_keys[j]].arrayValue.values
          );
        }
      }
    }

    setOutletAverageUsage((prevData) => {
      const updatedData = {
        ...prevData,
        [outletID]: averageOutletData.length !== 0 ? averageOutletData : [],
      };
      // Update localStorage
      localStorage.setItem("outletAverageUsage", JSON.stringify(updatedData));
      return updatedData;
    });
  };

  if (outletID) {
    await fetchAndSetAverageUsage(outletID);
  } else if (orgID) {
    const orgOutlets = await getDoc(doc(db, "organizations", orgID));
    if (orgOutlets && orgOutlets._document !== null) {
      const outlets_arr =
        orgOutlets._document.data.value.mapValue.fields.devices.arrayValue
          .values;
      if (outlets_arr) {
        for (let i = 0; i < outlets_arr.length; i++) {
          if (outlets_arr[i].stringValue[0] === "A") {
            let outletID = outlets_arr[i].stringValue + "_" + 0;
            await fetchAndSetAverageUsage(outletID);
          }
        }
      }
    }
  }
};

export function sumArrayIgnoreNegatives(arr) {
  let total = 0;
  for (let num of arr) {
    if (num !== -1) {
      total += num;
    }
  }
  return total;
}
