import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setOutletStatus,
  setOutletGroupStatus,
} from "../../../actions/outlets";
import { green, greyDark, greyLight, orange, white } from "../../../lib/colors";
import Outlet from "./outletPresentational";
import IonIcon from "@reacticons/ionicons";
import { sumArrayIgnoreNegatives } from "../../../functions/wattHours";

export default function OutletListContainer({
  outlets,
  prodTypes,
  setOutlets,
  timezone,
  wattHours,
  rate,
  height,
  width,
}) {
  const [filter, setFilter] = useState(true);
  const [filteredOutlets, setFilteredOutlets] = useState(JSON.parse(localStorage.getItem("outlets")));
  const dispatch = useDispatch();

  async function handleStatusChange({ id, status }) {
    dispatch(setOutletStatus(id, status));
  };

  //Add dailyUsage to the outlets array of objects
  let today = new Date().toLocaleDateString("en-US", {
    timeZone: timezone,
  });
  // Split the date string into components
  const [month, day, year] = today.split("/");
  // Construct the date in YYYY-MM-DD format
  today = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  //console.log(today)
  // Step 1: Filter the array for objects with today's date
  const filteredWattHours = wattHours.filter((obj) => obj.date === today);
  // Step 2: Update each object in the array to include a 'value' key with the sum of 'values'
  const sumWattHours = filteredWattHours.map((obj) => ({
    ...obj,
    dailyUsage: obj.hourlyWattHour.reduce(
      (acc, val) => (val > 0 ? acc + val : acc),
      0
    ),
  }));
  // Step 3: Convert sumWattHours to a Map for fast lookup
  const idToDailyUsageMap = new Map(
    sumWattHours.map((obj) => [obj.outletID, obj.dailyUsage])
  );
  // Step 4: Assign daily usage from sumWattHours to outlets based on id
  outlets.forEach((obj) => {
    if (idToDailyUsageMap.has(obj.id)) {
      obj.dailyUsage = idToDailyUsageMap.get(obj.id);
    }
    if (!obj.dailyUsage) {
      obj.dailyUsage = 0;
    }
  });

  const filterOutlets = (event=null, filterType) => {
    // sort by name
    filteredOutlets.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      const prodTypeA = a.productType;
      const prodTypeB = b.productType;
      const wifiStatusA = a.wifiStatus;
      const wifiStatusB = b.wifiStatus;
      const usageA = a.dailyUsage;
      const usageB = b.dailyUsage;
      const statusA = a.status;
      const statusB = b.status;
      const idA = a.id;
      const idB = b.id;
      if (filterType === "id") {
        if (idA < idB) {
          return -1;
        }
        if (idA > idB) {
          return 1;
        }
      } else if (filterType === "name") {
        if (filter) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        } else {
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
        }
      } else if (filterType === "prodType") {
        if (filter) {
          if (prodTypeA < prodTypeB) {
            return -1;
          }
          if (prodTypeA > prodTypeB) {
            return 1;
          }
        } else {
          if (prodTypeA < prodTypeB) {
            return 1;
          }
          if (prodTypeA > prodTypeB) {
            return -1;
          }
        }
      } else if (filterType === "wifiStatus") {
        if (filter) {
          if (wifiStatusA < wifiStatusB) {
            return -1;
          }
          if (wifiStatusA > wifiStatusB) {
            return 1;
          }
        } else {
          if (wifiStatusA < wifiStatusB) {
            return 1;
          }
          if (wifiStatusA > wifiStatusB) {
            return -1;
          }
        }
      } else if (filterType === "usage") {
        if (filter) {
          if (usageA < usageB) {
            return -1;
          }
          if (usageA > usageB) {
            return 1;
          }
        } else {
          if (usageA < usageB) {
            return 1;
          }
          if (usageA > usageB) {
            return -1;
          }
        }
      } else if (filterType === "status") {
        if (filter) {
          if (statusA < statusB) {
            return -1;
          }
          if (statusA > statusB) {
            return 1;
          }
        } else {
          if (statusA < statusB) {
            return 1;
          }
          if (statusA > statusB) {
            return -1;
          }
        }
      }
      // names must be equal
      return 0;
    });
    setFilteredOutlets(filteredOutlets);
    if (filterType !== "id") {
      setFilter(!filter);
    }
  };

  useEffect(()=>{
    filterOutlets(null,"id")
  },[]);

  // useEffect(() => {
  //   console.log('Outlets updated in OutletListContainer:', outlets);
  // }, [outlets]);

  const tableHeaderStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: greyDark,
    fontSize: width < 550 ? "3vw" : "20px",
    width: "80%",
  };

  //ensures outlet data exists before attempting to render by checking for organization id
  if (outlets.length > 0) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "25%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={tableHeaderStyle}>
                Outlet Name
                <button
                  onClick={(e) => filterOutlets(e, "name")}
                  style={{
                    borderWidth: 0,
                    backgroundColor: '#F2F4F8',
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  <IonIcon
                    name="filter-outline"
                    style={{
                      width: "22.5px",
                      height: "22.5px",
                      color: greyDark,
                    }}
                  />
                </button>
              </div>
            </div>
            <div
              style={{
                width: "25%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={tableHeaderStyle}>
                Product Type
                <button
                  onClick={(e) => filterOutlets(e, "prodType")}
                  style={{
                    borderWidth: 0,
                    backgroundColor: '#F2F4F8',
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  <IonIcon
                    name="filter-outline"
                    style={{
                      width: "22.5px",
                      height: "22.5px",
                      color: greyDark,
                    }}
                  />
                </button>
              </div>
            </div>
            <div
              style={{
                width: width > 805 ? "25%" : "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={tableHeaderStyle}>
                WiFi Status
                <button
                  onClick={(e) => filterOutlets(e, "wifiStatus")}
                  style={{
                    borderWidth: 0,
                    backgroundColor: '#F2F4F8',
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  <IonIcon
                    name="filter-outline"
                    style={{
                      width: "22.5px",
                      height: "22.5px",
                      color: greyDark,
                    }}
                  />
                </button>
              </div>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={tableHeaderStyle}>
                Daily Usage
                <button
                  onClick={(e) => filterOutlets(e, "usage")}
                  style={{
                    borderWidth: 0,
                    backgroundColor: '#F2F4F8',
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  <IonIcon
                    name="filter-outline"
                    style={{
                      width: "22.5px",
                      height: "22.5px",
                      color: greyDark,
                    }}
                  />
                </button>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={tableHeaderStyle}>
                On Off
                <button
                  onClick={(e) => filterOutlets(e, "status")}
                  style={{
                    borderWidth: 0,
                    backgroundColor: '#F2F4F8',
                    //marginRight: '10px',
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  <IonIcon
                    name="filter-outline"
                    style={{
                      width: "22.5px",
                      height: "22.5px",
                      color: greyDark,
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "650px",
              overflowY: "scroll",
              marginBottom: 25,
            }}
          >
            {filteredOutlets.map((outlet) => (
              <Outlet
                key={outlet.id}
                outlet={outlets.find(obj => obj.id === outlet.id)}
                prodTypes={prodTypes}
                outlets={outlets}
                setOutlets={setOutlets}
                // height={height}
                width={width}
                wattHours={wattHours}
                rate={rate}
                today={today}
              />
            ))}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            marginTop: "1%",
          }}
        ></div>
      </>
    );
  };
};
