import React, { useState, useEffect } from "react";
import Icon from "react-crud-icons";
import "../../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import {
  fetchIsOverviewAccount,
  orgSelectPress,
} from "../../functions/organization";
import {
  fetchOrganizationIDs,
  fetchSpecificTeamOrganizationIDs,
} from "../../functions/organization";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

//must detect in home that current user is memeber of ReVert Org
export default function OrganizationPicker({
  orgID,
  selectedOrgID,
  setSelectedOrgID,
  setTimezone,
  setRate,
  setSelectedApplianceType = null,
  width,
}) {
  //useEffect to get all of the possible organizations
  //useState variable to hold the list of orgs
  //drop down box with each org as an option
  //sets state of OrgID to be sent into outletList with selected option
  const [orgEdit, setOrgEdit] = useState(false);
  //const [orgName, setOrgName] = useState("ReVert Technologies, Inc.");
  //should be org name not orgID
  const [pickedOrg, setPickedOrg] = useState(selectedOrgID
    //localStorage.getItem("selectedOrgID") || ""
  );
  const [allOrgs, setAllOrgs] = useState([]);

  useEffect(() => {
    if (orgID === "LZqI3R6MInUuwtgtROPK") {
      fetchOrganizationIDs(setAllOrgs);
    }
    else{
      fetchSpecificTeamOrganizationIDs(orgID, setAllOrgs);
    }
  }, [orgID]);

  useEffect(() => {
    if(!selectedOrgID && allOrgs.length > 1){
      orgSelectPress(
        pickedOrg,
        allOrgs[0].uid,
        setPickedOrg,
        setOrgEdit,
        setSelectedOrgID,
        setTimezone,
        setRate
      );
    }
  }, [allOrgs]);

  const selectOrgPress = (event) => {
    //note: function that updates selected orgID
    orgSelectPress(
      pickedOrg,
      event.target.value,
      setPickedOrg,
      setOrgEdit,
      setSelectedOrgID,
      setTimezone,
      setRate
    );
    if (setSelectedApplianceType) setSelectedApplianceType("");
  };

  let parentOrgs = []
  let filteredOrgs = allOrgs
  allOrgs.map((org) => {
    if (org.child_orgs){
      parentOrgs.push(org)
    }
  })

  parentOrgs
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
    })
    
  return (
    <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems:'center',
        //backgroundColor:'red',
      }}>
        {/* <FormHelperText style={{fontSize: width < 1300 ? 12.5: 15, fontWeight:'500',marginRight:'35px'}}>
          Change Organization:
        </FormHelperText> */}
        <FormControl sx={{ m: 1, minWidth: 200, width:275 }}>
          <Select
            native
            value={pickedOrg}
            onChange={selectOrgPress}
          >
            {/*assign overview accounts as groups*/}
            {parentOrgs.map((parent_org) => {
              let child_orgs = [];
              let indexToRemove;

              //identify the child orgs
              parent_org.child_orgs.map((org) => {
                let child_org = allOrgs.filter(
                  (org1) => org1.uid === org
                );
                child_orgs.push(child_org[0]);

                //Find the index of the child Org and filter from allOrgs array
                filteredOrgs = filteredOrgs.filter(obj => obj.uid !== org);
              });
              //filter parent orgs from allOrgs array
              filteredOrgs = filteredOrgs.filter(obj => obj.uid !== parent_org.uid);
              return (
                <optgroup
                label={parent_org.name}
                key={parent_org.name}
                >
                  <option
                    key={parent_org.uid}
                    value={parent_org.uid}
                  >
                    {parent_org.name}
                  </option>
                  {child_orgs
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                      })
                      .map((child) => {
                        return (
                          <option
                            key={child.uid}
                            value={child.uid}
                          >
                            {child.name}
                          </option>
                        );
                    })}
                </optgroup>
              )
            })}
          {filteredOrgs
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
              })
              .map((org) => {
                return (
                  <option
                    key={org.uid}
                    value={org.uid}
                  >
                    {org.name}
                  </option>
                );
            })}
          </Select>
        </FormControl>  
    </div>
  );
}


{/*<select
value={pickedOrg}
onChange={selectOrgPress}
style={{
  marginRight: '10px',
  fontSize: "15px",
  borderWidth: "0px",
  backgroundColor:'#F2F4F8',
}}
>*/}
{/*assign overview accounts as groups*/}
{/*parentOrgs.map((parent_org) => {
  let child_orgs = [];
  let indexToRemove;

  //identify the child orgs
  parent_org.child_orgs.map((org) => {
    let child_org = allOrgs.filter(
      (org1) => org1.uid === org
    );
    child_orgs.push(child_org[0]);

    //Find the index of the child Org and filter from allOrgs array
    filteredOrgs = filteredOrgs.filter(obj => obj.uid !== org);
  });
  //filter parent orgs from allOrgs array
  filteredOrgs = filteredOrgs.filter(obj => obj.uid !== parent_org.uid);
  return (
    <optgroup
    label={parent_org.name}
    key={parent_org.name}
    >
      {child_orgs
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
          })
          .map((child) => {
            return (
              <option
                key={child.uid}
                value={child.uid}
              >
                {child.name}
              </option>
            );
        })}
    </optgroup>
  )
})}
{filteredOrgs
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
    })
    .map((org) => {
      return (
        <option
          key={org.uid}
          value={org.uid}
        >
          {org.name}
        </option>
      );
  })}
</select>*/}